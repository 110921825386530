import { apiUrl } from './../constants'

export const fetchEmail = (email) => {
    return fetch(`${apiUrl}/fetchemail`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(email)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log("Login Err: ", err)
    )
}

export const fetchVerifyOTP = (data) => {
    return fetch(`${apiUrl}/verify-otp`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log("Login Err: ", err)
    )
}

export const requestOTPPassword = (data) => {
    return fetch(`${apiUrl}/request-otp-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/josn',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log('OTP for Password: ', err)
    )
}

export const verifyAndResetPassword = (data) => {
    return fetch(`${apiUrl}/verify-reset-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/josn',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log('OTP for Password: ', err)
    )
}

export const registerUser = (data) => {
    return fetch(`${apiUrl}/register-user`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err =>
        console.log("Login Err: ", err)
    )
}

export const loginUser = (data) => {
    return fetch(`${apiUrl}/login-user`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const clearLocalStorage = () => {
    localStorage.clear();
    console.log("COOKIES_CLEARED")
};

export const checkServer = () => {
    return fetch(`${apiUrl}/check-server`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-type': 'application/json'
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log(err)
        return err.json()
    })
}
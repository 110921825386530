import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextField from '@mui/material/TextField';
import { useEffect, useRef, useState } from 'react';
import { fetchEmail, fetchVerifyOTP, loginUser, registerUser, requestOTPPassword, verifyAndResetPassword } from '../actions/authActions';
import { Checkbox, CircularProgress, Collapse, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuthStore } from '../store/store';
import { Link, useNavigate } from 'react-router-dom';
import video_home from "./assets/video_home1.mp4"
import { Helmet } from 'react-helmet-async';
const AuthComponent = () => {

    const [isLoading, setIsLoading] = useState(false)

    const [values, setValues] = useState({
        sessionId: '',
        email: '',
        password: '',
        userName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        otp: '',
        otpPassword: '',
        newPassword: '',
        confirmPassword: '',
        successMessage: '',
        termsAccepted: false,
        field: '',
        error: '',
        errorMessage: '',
        errors: {}
    })

    const testData = [
        'Student / Freelancer',
        'In House Employee',
        'Project host'
    ]

    const navigate = useNavigate()

    // var passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    const setStateUser = useAuthStore(state => state.setUser)

    const { firstName, middleName, lastName, field, sessionId, email, otp, successMessage, newPassword, confirmPassword, password, termsAccepted, userName, error, errorMessage } = values
    const [showPassword, setShowPassword] = useState(false);
    const [showOTPForm, setShowOTPForm] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [showRegistrationForm, setShowRegistrationForm] = useState(false);
    const [otpTimer, setOtpTimer] = useState(0);
    const timerRef = useRef(null);

    const startOTPTimer = (seconds) => {
        if (timerRef.current) clearInterval(timerRef.current); // Clear previous timer
        setOtpTimer(seconds);

        timerRef.current = setInterval(() => {
            setOtpTimer(prev => {
                if (prev <= 1) {
                    clearInterval(timerRef.current);
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);
    };

    // Convert seconds to MM:SS format
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    // Clear timer when component unmounts
    useEffect(() => {
        return () => clearInterval(timerRef.current);
    }, []);


    const emailRegex = /\S+@\S+\.\S+/;

    const capitalizeFirstLetter = (value) => {
        if (!value) return '';
        return value.charAt(0).toUpperCase() + value.slice(1);
    };

    const handleChange = name => event => {
        setValues({
            ...values,
            error: '',
            errorMessage: '',
            errors: {},
            [name]: (name === 'password' || name === 'email')
                ? event.target.value
                : capitalizeFirstLetter(event.target.value)
        });
    };

    const handleSubmit = (email) => {
        setIsLoading(true);
        if (emailRegex.test(email)) {
            fetchEmail({ email: email })
                .then(res => {
                    if (res.userExist) {
                        // User exists, proceed with login
                        setValues({
                            ...values,
                            sessionId: res.sessionId,
                            userName: res.userName,
                            error: '',
                            errorMessage: ''
                        });
                        setShowRegistrationForm(false);
                        setIsFirstVisible(!isFirstVisible);
                    } else {
                        // OTP Handling
                        let remainingSeconds = res.otpExpiresIn || 0; // Use server-provided expiry time

                        setValues({
                            ...values,
                            sessionId: res.sessionId,
                            error: '',
                            errorMessage: res.message,
                            otpExpiresIn: remainingSeconds
                        });

                        setShowOTPForm(true);
                        setValues({ ...values, error: '', errorMessage: '' })
                        if (remainingSeconds > 0) {
                            startOTPTimer(remainingSeconds);
                        }
                    }
                })
                .catch(error => {
                    console.error("Error fetching email:", error);
                    setValues({ ...values, errorMessage: "An error occurred. Please try again later." });
                })
                .finally(() => setIsLoading(false));
        } else {
            setValues({ ...values, errorMessage: "Please enter a valid Email" });
            setIsLoading(false);
        }
    };

    const handleVerifyOTP = (otp) => {
        setIsLoading(true);
        fetchVerifyOTP({ email: values.email, otp })
            .then(res => {
                console.log(res)
                if (res.otpVerified) {
                    setValues({
                        ...values,
                        sessionId: res.sessionId,
                        error: '',
                        errorMessage: ''
                    });
                    setShowRegistrationForm(true);
                    setIsFirstVisible(!isFirstVisible);
                } else {
                    setValues({ ...values, errorMessage: res.error || "Invalid OTP. Please try again." });
                }
            })
            .catch(error => {
                console.log(error)
                setValues({ ...values, errorMessage: error.error || "Invalid OTP. Please try again." });
            })
            .finally(() => setIsLoading(false));
    };


    const handleRequestOTPPassword = () => {
        setIsLoading(true)
        requestOTPPassword({ email: email }).then(res => {
            if (res.otpSent) {
                let remainingSeconds = res.otpExpiresIn || 0; // Use server-provided expiry time

                setValues({
                    ...values,
                    sessionId: res.sessionId,
                    error: '',
                    errorMessage: res.message,
                    otpExpiresIn: remainingSeconds
                });

                setIsLoading(false)

                setShowResetPassword(true);
                setValues({ ...values, error: '', errorMessage: '' })
                if (remainingSeconds > 0) {
                    startOTPTimer(remainingSeconds);
                }
            } else {
                setValues({ ...values, errorMessage: 'There is a problem sending Email, please try after sometime' })
                setIsLoading(false)
            }
        })
    }

    const handleResetPasswordChange = (field) => (event) => {
        const { value } = event.target;
        setValues((prevValues) => {
            let errors = { ...prevValues.errors };
            let updatedValues = { ...prevValues, [field]: value };

            if (field === "newPassword") {
                if (value.length < 8) {
                    errors.newPassword = "Password must be at least 8 characters long.";
                } else {
                    delete errors.newPassword;
                }
            }

            if (field === "confirmPassword") {
                if (value !== updatedValues.newPassword) {
                    errors.confirmPassword = "Passwords do not match.";
                } else {
                    delete errors.confirmPassword;
                }
            }

            const isButtonDisabled =
                updatedValues.newPassword.length < 8 ||
                updatedValues.confirmPassword.length < 8 ||
                updatedValues.confirmPassword !== updatedValues.newPassword;

            return {
                ...updatedValues,
                errors,
                isButtonDisabled,
            };
        });
    };

    const handleVerifyOTPPassword = () => {
        if (otp.length !== 6) {
            setValues((prevValues) => ({
                ...prevValues,
                errorMessage: "OTP must be 6 characters long.",
            }));
            return;
        }
        verifyAndResetPassword({ email, otp, newPassword })
            .then((res) => {
                if (res.error) {
                    setValues((prevValues) => ({
                        ...prevValues,
                        errorMessage: res.error,
                    }));
                } else {
                    setValues((prevValues) => ({
                        ...prevValues,
                        successMessage: "Password updated successfully!",
                        errorMessage: "",
                    }));
                    setShowResetPassword(false)
                }
            })
            .catch((err) => {
                setValues((prevValues) => ({
                    ...prevValues,
                    errorMessage: "Something went wrong. Please try again.",
                }));
            });
    };


    const handlePassword = (password) => {
        if (password !== '') {
            let data = {
                sessionId: sessionId,
                password: password
            }
            loginUser(data).then(res => {
                if (res.status === false) {
                    return setValues({ ...values, error: true, errorMessage: res.error })
                }
                if (res.status === true) {
                    setStateUser(res)
                    localStorage.setItem('user', JSON.stringify(res))
                    if (res.user.isAdvancedAccount) {
                        return navigate('/advanced-dashboard')
                    } else {
                        return navigate('/dashboard')
                    }
                }
            }).catch(err => {
                setValues({ ...values, error: true, errorMessage: "Something went wrong" })
                console.log(err)
            })
        } else {
            setValues({ ...values, errorMessage: "Please provide valid data" })
        }
        setIsLoading(false)
    }

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [isFirstVisible, setIsFirstVisible] = useState(true);

    const handleSubmitRegister = (firstName, field, middleName, lastName, password) => {
        let fieldErrors = {};

        if (!firstName.trim()) fieldErrors.firstName = "First Name is required";
        if (!lastName.trim()) fieldErrors.lastName = "Last Name is required";
        if (!field) fieldErrors.field = "User type is required";
        if (termsAccepted === false) fieldErrors.termsAccepted = "Accept before moving further"
        if (!password) {
            fieldErrors.password = "Password is required";
        } else if (password.length <= 7) {
            fieldErrors.password = "Password must be at least 8 characters";
        }

        if (Object.keys(fieldErrors).length > 0) {
            return setValues({ ...values, errors: fieldErrors });
        }

        let data = {
            firstName: firstName,
            middleName: middleName,
            lastName: lastName,
            field: field,
            sessionId: sessionId,
            password: password,
        };

        registerUser(data)
            .then((res) => {
                setStateUser(res);
                localStorage.setItem("user", JSON.stringify(res));
                if (res.user.isAdvancedAccount) {
                    return navigate("/advanced-dashboard");
                } else {
                    return navigate("/dashboard");
                }
            })
            .catch((err) => {
                console.error("Registration Error:", err);
            });
    };

    return (
        <div style={{ height: '100vh' }}>
            <Helmet>
                <title>Get Started with DevSemble - Begin Your Journey</title>
                <meta
                    name="description"
                    content="Ready to start your journey with DevSemble? Sign up, create a profile, and dive into a world of opportunities to collaborate and grow in the tech ecosystem."
                />
            </Helmet>
            <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ height: '100vh' }}>
                <div className="col-lg-6 col-12 d-flex flex-wrap justify-content-center align-items-center bg-light position-relative" style={{ height: '100vh', zIndex: '11', overflowX: "hidden" }}>
                    {/* Floating Shapes in the background */}
                    <div className="floating-shape shape1"></div>
                    <div className="floating-shape shape2"></div>
                    <div className="floating-shape shape3"></div>

                    {/* Centered Video */}
                    <div className={`d-flex flex-wrap justify-content-center ${window.innerWidth >= 992 ? 'align-items-center' : 'align-content-around'} col-12`}
                        style={{ height: '100vh', zIndex: '12' }}>
                        <video autoPlay loop muted className="centered-video">
                            <source src={video_home} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                        <div className='d-lg-none d-flex justify-content-center align-items-center col-12' style={{ minHeight: '20vh', height: 'auto', overflow: 'visible' }}>
                            <div className={`box col-10 d-flex flex-wrap justify-content-center align-items-center ${isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`}>
                                <div className='col-12' >
                                    <Collapse in={!showOTPForm}>
                                        <div>
                                            <div className="fw-bold display-4">
                                                Get Started
                                            </div>
                                            <div className="fw-bold fs-2">
                                                with your email
                                            </div>
                                            <small className='fw-bold text-muted'>*For in house employees use your company's official email</small>
                                            <div className='py-3'>
                                                <div className='col-12'>
                                                    <TextField
                                                        required={true}
                                                        className='m-0 col-12 bg-light'
                                                        error={errorMessage === "" ? false : true}
                                                        type='email'
                                                        value={email}
                                                        label={errorMessage === '' ? "Email" : errorMessage}
                                                        onChange={handleChange('email')}
                                                        variant="outlined" />
                                                </div>
                                                <div className='py-3 d-flex justify-content-between align-items-center'>
                                                    <div className='text-danger fw-bold'>
                                                        {errorMessage === '' ? "" : errorMessage}
                                                    </div>
                                                    <button className='fw-bold btn btn-primary rounded-pill d-flex align-items-center'
                                                        onClick={() => {
                                                            handleSubmit(email)
                                                        }}>
                                                        Lets Go {isLoading ? <CircularProgress size={20} className='text-white ms-2' /> : <FontAwesomeIcon icon={faArrowRight} />}
                                                    </button>
                                                </div>
                                                <div className='text-center'>
                                                    <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                </div>
                                            </div>
                                        </div>
                                    </Collapse>
                                    <Collapse in={showOTPForm}>
                                        <div className="py-2">
                                            <button
                                                className="btn btn-sm btn-warning fw-bold rounded-pill"
                                                onClick={() => {
                                                    setValues({ ...values, sessionId: '' });
                                                    setShowOTPForm(false);
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                                        </div>
                                        <div className="fw-bold display-4">OTP send</div>
                                        <div style={{ wordBreak: 'break-word' }} className="fw-bold fs-3">{`to ${email}`}</div>
                                        <p>OTP Expires In: {formatTime(otpTimer)}</p>
                                        <div className="py-3">
                                            <form onSubmit={(e) => {
                                                e.preventDefault(); // Prevent page reload
                                                handleVerifyOTP(otp); // Your submit logic
                                            }}>
                                                <div className="col-12">
                                                    <TextField
                                                        required={true}
                                                        className="m-0 col-12 bg-light"
                                                        error={errorMessage === "" ? false : true}
                                                        type="otp"
                                                        value={otp}
                                                        label={errorMessage === '' ? "Please enter OTP" : errorMessage}
                                                        onChange={handleChange('otp')}
                                                        variant="outlined" />
                                                </div>
                                                <div className="py-3 d-flex justify-content-between align-items-center">
                                                    <div className="text-danger fw-bold">
                                                        {errorMessage === '' ? "" : errorMessage}
                                                    </div>
                                                    <button type="submit" className="fw-bold btn btn-primary rounded-pill">
                                                        Verify <FontAwesomeIcon icon={faArrowRight} />
                                                    </button>
                                                </div>
                                                <div className='text-center'>
                                                    <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                </div>
                                            </form>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                            <div className={`box col-11 d-flex flex-wrap justify-content-center align-items-center ${!isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`} style={{ height: 'auto' }}>
                                {showRegistrationForm ?
                                    <div className='col-12 bg-light p-3 shadow-lg' style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        <div className='py-2'>
                                            <button className='btn btn-sm btn-warning fw-bold rounded-pill' onClick={() => {
                                                setValues({ ...values, sessionId: '' })
                                                setIsFirstVisible(!isFirstVisible)
                                            }}>
                                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                                        </div>
                                        <div className=''>
                                            <div className="fw-bold fs-2">
                                                Get Yourself Register
                                            </div>
                                            <div className="py-3">
                                                <form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmitRegister(firstName, field, middleName, lastName, password, termsAccepted);
                                                }}>
                                                    <div className="col-12">
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.firstName)}
                                                            helperText={values.errors?.firstName}
                                                            id="outlined-basic"
                                                            type="text"
                                                            label="First Name"
                                                            onChange={handleChange("firstName")}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.middleName)}
                                                            helperText={values.errors?.middleName}
                                                            id="outlined-basic"
                                                            type="text"
                                                            label="Middle Name"
                                                            onChange={handleChange("middleName")}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.lastName)}
                                                            helperText={values.errors?.lastName}
                                                            id="outlined-basic"
                                                            type="text"
                                                            label="Last Name"
                                                            onChange={handleChange("lastName")}
                                                            variant="outlined"
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.password)}
                                                            helperText={values.errors?.password}
                                                            type={showPassword ? "text" : "password"}
                                                            value={password}
                                                            label="Password"
                                                            onChange={handleChange("password")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <div className="py-2">
                                                            <InputLabel
                                                                id="demo-multiple-chip-label"
                                                                style={{
                                                                    color: errorMessage === '' || error !== "fieldErr" ? "#0275d8" : "red",
                                                                }}
                                                            >
                                                                {errorMessage === '' || error !== "fieldErr" ? "Select type of user" : errorMessage}
                                                            </InputLabel>
                                                            <Select
                                                                labelId="demo-multiple-chip-label"
                                                                id="demo-multiple-chip"
                                                                style={{ width: "100%", color: "black" }}
                                                                error={Boolean(values.errors?.field)}
                                                                value={field}
                                                                defaultValue="Student / Freelancer"
                                                                onChange={handleChange("field")}
                                                                input={<OutlinedInput id="select-multiple-chip" label="" />}
                                                            >
                                                                {testData.map((name) => (
                                                                    <MenuItem key={name} value={name}>
                                                                        {name}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {values.errors?.field && (
                                                                <span style={{ color: "red" }}>{values.errors.field}</span>
                                                            )}
                                                        </div>
                                                        <div className="my-1">
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={termsAccepted}
                                                                        onChange={handleChange('termsAccepted')}
                                                                        color="primary"
                                                                        required
                                                                    />
                                                                }
                                                                label={
                                                                    <span>
                                                                        I agree to the{" "}
                                                                        <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                                            Terms of Service
                                                                        </Link>{" "}
                                                                        and{" "}
                                                                        <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                                            Privacy Policy
                                                                        </Link>.
                                                                    </span>
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="py-3 d-flex justify-content-between">
                                                        <div className="text-danger fw-bold">
                                                            {errorMessage === '' ? "" : errorMessage}
                                                        </div>
                                                        <button
                                                            type="submit"
                                                            className="fw-bold btn btn-primary rounded-pill"
                                                            disabled={!termsAccepted} // Disable button if terms are not accepted
                                                        >
                                                            Register
                                                        </button>
                                                    </div>
                                                    <div className='text-center'>
                                                        <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className='col-12 bg-light p-3 shadow-lg' style={{ borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>
                                        <div className='py-2'>
                                            <button className='btn btn-sm btn-warning fw-bold rounded-pill' onClick={() => {
                                                setValues({ ...values, sessionId: '' })
                                                setIsFirstVisible(!isFirstVisible)
                                            }}>
                                                <FontAwesomeIcon icon={faArrowLeft} /> Back
                                            </button>
                                        </div>
                                        <Collapse in={!showResetPassword}>
                                            <div>
                                                <div className="fw-bold fs-2">
                                                    Welcome Back
                                                </div>
                                                <div className="fw-bold display-4">
                                                    {userName}
                                                </div>
                                                <div className='text-success fw-bold'>
                                                    {!successMessage ? null : successMessage}
                                                </div>
                                                <div className='py-3'>
                                                    <div className='col-12'>
                                                        <TextField
                                                            className='m-0 col-12 bg-light'
                                                            error={errorMessage === "" ? false : true}
                                                            type={showPassword ? "text" : "password"}
                                                            value={password}
                                                            label={errorMessage === '' ? "Password" : errorMessage}
                                                            onChange={handleChange('password')}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            variant="outlined" />
                                                    </div>
                                                    <div className='py-3 d-flex justify-content-between align-items-center'>
                                                        <button disabled={isLoading} onClick={() => handleRequestOTPPassword()} style={{ cursor: 'pointer' }} className='text-danger bg-transparent border-0 d-flex align-items-center'>
                                                            <u>Forgot Password</u> {isLoading ? <CircularProgress size={20} className='text-danger ms-2' /> : null}
                                                        </button>
                                                        <div className='text-danger fw-bold'>
                                                            {errorMessage === '' ? "" : errorMessage}
                                                        </div>
                                                        <button className='fw-bold btn btn-primary rounded-pill'
                                                            onClick={() => {
                                                                handlePassword(password)
                                                            }}>
                                                            Login
                                                        </button>
                                                    </div>
                                                    <div className='text-center'>
                                                        <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                    </div>
                                                </div>
                                            </div>
                                        </Collapse>
                                        <Collapse in={showResetPassword}>
                                            <div className="fw-bold display-4">OTP send</div>
                                            <div className="fw-bold fs-3">{`to ${email}`}</div>
                                            <p>OTP Expires In: {formatTime(otpTimer)}</p>
                                            <div className="py-3">
                                                <form onSubmit={(e) => {
                                                    e.preventDefault(); // Prevent page reload
                                                    handleVerifyOTPPassword(otp, email, newPassword); // Your submit logic
                                                }}>
                                                    <div className="col-12">
                                                        <TextField
                                                            required={true}
                                                            className="my-2 col-12 bg-light"
                                                            error={errorMessage === "" ? false : true}
                                                            type="otp"
                                                            value={otp}
                                                            label={errorMessage === '' ? "Please enter OTP" : errorMessage}
                                                            onChange={handleChange('otp')}
                                                            variant="outlined" />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.newPassword)}
                                                            helperText={values.errors?.newPassword}
                                                            type={showPassword ? "text" : "password"}
                                                            value={newPassword}
                                                            label="New Password"
                                                            onChange={handleResetPasswordChange("newPassword")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                        <TextField
                                                            className="my-2 col-12 bg-light"
                                                            error={Boolean(values.errors?.confirmPassword)}
                                                            helperText={values.errors?.confirmPassword}
                                                            type={showPassword ? "text" : "password"}
                                                            value={confirmPassword}
                                                            label="Confirm New Password"
                                                            onChange={handleResetPasswordChange("confirmPassword")}
                                                            variant="outlined"
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            edge="end"
                                                                        >
                                                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="py-3 d-flex justify-content-between align-items-center">
                                                        <div className="text-danger fw-bold">
                                                            {errorMessage === '' ? "" : errorMessage}
                                                        </div>
                                                        <button type="submit" disabled={values.isButtonDisabled} className="fw-bold btn btn-primary rounded-pill">
                                                            Reset Password
                                                        </button>
                                                    </div>
                                                    <div className='text-center'>
                                                        <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                                    </div>
                                                </form>
                                            </div>
                                        </Collapse>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-12 d-none d-lg-flex flex-wrap justify-content-center align-items-center">
                    <div className={`col-6 box d-flex flex-wrap justify-content-center align-items-center ${isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`}>
                        <div className="col-8">
                            <Collapse in={!showOTPForm}>
                                <div>
                                    <div className="fw-bold display-4">Get Started</div>
                                    <div className="fw-bold fs-2">with your email</div>
                                    <small className='fw-bold text-muted'>*For in house employees use your company's official email</small>
                                    <div className="py-3">
                                        <form onSubmit={(e) => {
                                            e.preventDefault(); // Prevent page reload
                                            handleSubmit(email); // Your submit logic
                                        }}>
                                            <div className="col-12">
                                                <TextField
                                                    required={true}
                                                    className="m-0 col-12"
                                                    error={errorMessage === "" ? false : true}
                                                    type="email"
                                                    value={email}
                                                    label={errorMessage === '' ? "Email" : errorMessage}
                                                    onChange={handleChange('email')}
                                                    variant="outlined" />
                                            </div>
                                            <div className="py-3 d-flex justify-content-between align-items-center">
                                                <div className="text-danger fw-bold">
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button type="submit" className="fw-bold btn btn-primary rounded-pill">
                                                    Lets Go <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Collapse>
                            <Collapse in={showOTPForm}>
                                <div>
                                    <div className="py-2">
                                        <button
                                            className="btn btn-sm btn-warning fw-bold rounded-pill"
                                            onClick={() => {
                                                setValues({ ...values, sessionId: '' });
                                                setShowOTPForm(false);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faArrowLeft} /> Back
                                        </button>
                                    </div>
                                    <div className="fw-bold display-4">OTP send</div>
                                    <div className="fw-bold fs-3">{`to ${email}`}</div>
                                    <p>OTP Expires In: {formatTime(otpTimer)}</p>
                                    <div className="py-3">
                                        <form onSubmit={(e) => {
                                            e.preventDefault(); // Prevent page reload
                                            handleVerifyOTP(otp); // Your submit logic
                                        }}>
                                            <div className="col-12">
                                                <TextField
                                                    required={true}
                                                    className="m-0 col-12"
                                                    error={errorMessage === "" ? false : true}
                                                    type="otp"
                                                    value={otp}
                                                    label={errorMessage === '' ? "Please enter OTP" : errorMessage}
                                                    onChange={handleChange('otp')}
                                                    variant="outlined" />
                                            </div>
                                            <div className="py-3 d-flex justify-content-between align-items-center">
                                                <div className="text-danger fw-bold">
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button type="submit" className="fw-bold btn btn-primary rounded-pill">
                                                    Verify <FontAwesomeIcon icon={faArrowRight} />
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </div>
                    <div className={`col-6 box d-flex flex-wrap justify-content-center align-items-center ${!isFirstVisible ? 'fade-in-right-to-left' : 'fade-out-right-to-left'}`}>
                        {showRegistrationForm ?
                            <div className="col-8">
                                <div className="py-2">
                                    <button
                                        className="btn btn-sm btn-warning fw-bold rounded-pill"
                                        onClick={() => {
                                            setValues({ ...values, sessionId: '' });
                                            setIsFirstVisible(!isFirstVisible);
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                                    </button>
                                </div>
                                <div>
                                    <div className="fw-bold fs-2">Get Yourself Register</div>
                                    <div className="py-3">
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            handleSubmitRegister(firstName, field, middleName, lastName, password, termsAccepted);
                                        }}>
                                            <div className="col-12">
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.firstName)}
                                                    helperText={values.errors?.firstName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    label="First Name"
                                                    onChange={handleChange("firstName")}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.middleName)}
                                                    helperText={values.errors?.middleName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    label="Middle Name"
                                                    onChange={handleChange("middleName")}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.lastName)}
                                                    helperText={values.errors?.lastName}
                                                    id="outlined-basic"
                                                    type="text"
                                                    label="Last Name"
                                                    onChange={handleChange("lastName")}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.password)}
                                                    helperText={values.errors?.password}
                                                    type={showPassword ? "text" : "password"}
                                                    value={password}
                                                    label="Password"
                                                    onChange={handleChange("password")}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <div className="py-2">
                                                    <InputLabel
                                                        id="demo-multiple-chip-label"
                                                        style={{
                                                            color: errorMessage === '' || error !== "fieldErr" ? "#0275d8" : "red",
                                                        }}
                                                    >
                                                        {errorMessage === '' || error !== "fieldErr" ? "Select type of user" : errorMessage}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="demo-multiple-chip-label"
                                                        id="demo-multiple-chip"
                                                        style={{ width: "100%", color: "black" }}
                                                        error={Boolean(values.errors?.field)}
                                                        value={field}
                                                        defaultValue="Student / Freelancer"
                                                        onChange={handleChange("field")}
                                                        input={<OutlinedInput id="select-multiple-chip" label="" />}
                                                    >
                                                        {testData.map((name) => (
                                                            <MenuItem key={name} value={name}>
                                                                {name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {values.errors?.field && (
                                                        <span style={{ color: "red" }}>{values.errors.field}</span>
                                                    )}
                                                </div>
                                                <div className="my-1">
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={termsAccepted}
                                                                onChange={handleChange('termsAccepted')}
                                                                color="primary"
                                                                required
                                                            />
                                                        }
                                                        label={
                                                            <span>
                                                                I agree to the{" "}
                                                                <Link to="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
                                                                    Terms of Service
                                                                </Link>{" "}
                                                                and{" "}
                                                                <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                                                                    Privacy Policy
                                                                </Link>.
                                                            </span>
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className="py-3 d-flex justify-content-between align-items-center">
                                                <div className="text-danger fw-bold">
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button
                                                    type="submit"
                                                    className="fw-bold btn btn-primary rounded-pill"
                                                    disabled={!termsAccepted} // Disable button if terms are not accepted
                                                >
                                                    Register
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="col-8">
                                <div className="py-2">
                                    <button className="btn btn-sm btn-warning fw-bold rounded-pill" onClick={() => {
                                        setValues({ ...values, sessionId: '' });
                                        setIsFirstVisible(!isFirstVisible);
                                        setShowResetPassword(false)
                                    }}>
                                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                                    </button>
                                </div>
                                <Collapse in={!showResetPassword}>
                                    <div>
                                        <div className="fw-bold fs-2">Welcome Back</div>
                                        <div className="fw-bold display-4">{userName}</div>
                                        <div className='text-success fw-bold'>
                                            {!successMessage ? null : successMessage}
                                        </div>
                                        <div className="py-3">
                                            <form onSubmit={(e) => {
                                                e.preventDefault();
                                                handlePassword(password);
                                            }}>
                                                <div className="col-12">
                                                    <TextField
                                                        className="m-0 col-12"
                                                        error={errorMessage === "" ? false : true}
                                                        type={showPassword ? "text" : "password"}
                                                        value={password}
                                                        label={errorMessage === '' ? "Password" : errorMessage}
                                                        onChange={handleChange('password')}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                        variant="outlined"
                                                    />
                                                </div>
                                                <div className="py-3 d-flex justify-content-between">
                                                    <div>
                                                        <button
                                                            type="button"  // ✅ Prevents form submission
                                                            disabled={isLoading}
                                                            onClick={() => handleRequestOTPPassword()}
                                                            style={{ cursor: 'pointer' }}
                                                            className='text-danger bg-transparent border-0 d-flex align-items-center'
                                                        >
                                                            <u>Forgot Password</u> {isLoading ? <CircularProgress size={20} className='text-danger ms-2' /> : null}
                                                        </button>
                                                    </div>
                                                    <div className="text-danger fw-bold">
                                                        {errorMessage === '' ? "" : errorMessage}
                                                    </div>
                                                    <button
                                                        type="submit"  // ✅ Ensures form submission on Enter
                                                        className="fw-bold btn btn-primary rounded-pill"
                                                    >
                                                        Login
                                                    </button>
                                                </div>
                                                <div className='text-center'>
                                                    <small className='fw-bold text-muted'>For any queries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask DevSemble</Link></small>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </Collapse>
                                <Collapse in={showResetPassword}>
                                    <div className="fw-bold display-4">OTP send</div>
                                    <div className="fw-bold fs-3">{`to ${email}`}</div>
                                    <p>OTP Expires In: {formatTime(otpTimer)}</p>
                                    <div className="py-3">
                                        <form onSubmit={(e) => {
                                            e.preventDefault(); // Prevent page reload
                                            handleVerifyOTPPassword(otp, email, newPassword); // Your submit logic
                                        }}>
                                            <div className="col-12">
                                                <TextField
                                                    required={true}
                                                    className="my-2 col-12"
                                                    error={errorMessage === "" ? false : true}
                                                    type="otp"
                                                    value={otp}
                                                    label={errorMessage === '' ? "Please enter OTP" : errorMessage}
                                                    onChange={handleChange('otp')}
                                                    variant="outlined" />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.newPassword)}
                                                    helperText={values.errors?.newPassword}
                                                    type={showPassword ? "text" : "password"}
                                                    value={newPassword}
                                                    label="New Password"
                                                    onChange={handleResetPasswordChange("newPassword")}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                <TextField
                                                    className="my-2 col-12 bg-light"
                                                    error={Boolean(values.errors?.confirmPassword)}
                                                    helperText={values.errors?.confirmPassword}
                                                    type={showPassword ? "text" : "password"}
                                                    value={confirmPassword}
                                                    label="Confirm New Password"
                                                    onChange={handleResetPasswordChange("confirmPassword")}
                                                    variant="outlined"
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    aria-label="toggle password visibility"
                                                                    onClick={handleClickShowPassword}
                                                                    edge="end"
                                                                >
                                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </div>
                                            <div className="py-3 d-flex justify-content-between align-items-center">
                                                <div className="text-danger fw-bold">
                                                    {errorMessage === '' ? "" : errorMessage}
                                                </div>
                                                <button type="submit" disabled={values.isButtonDisabled} className="fw-bold btn btn-primary rounded-pill">
                                                    Reset Password
                                                </button>
                                            </div>
                                            <div className='text-center'>
                                                <small className='fw-bold text-muted'>For any quries feel free to <Link to="/ask-devsemble" className='fw-bold'>Ask devSemble</Link></small>
                                            </div>
                                        </form>
                                    </div>
                                </Collapse>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthComponent
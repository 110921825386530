import { apiUrl } from './../constants'

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {}; // Safely retrieve user
    return user?.token || ""; // Return token or fallback to empty string
};

export const submitProfile = (profileData) => {
    const token = getToken();
    return fetch(`${apiUrl}/create-profile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(profileData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("PROFILE-CREATE-ERR: ", err)
    })
}

export const getProfiles = () => {
    const token = getToken();
    return fetch(`${apiUrl}/get-profiles`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const getUserProfile = (userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/get-user-profile/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const submitReviewerProfile = (profileData) => {
    const token = getToken();
    return fetch(`${apiUrl}/create-reviewer-profile`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(profileData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("PROFILE-CREATE-ERR: ", err)
    })
}

export const updateProfile = (updateData) => {
    const token = getToken();
    return fetch(`${apiUrl}/update-user-profile/${updateData.userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("PROFILE UPDATE ERR: ", err)
    })
}

export const updateAboutYou = (updateData) => {
    const token = getToken();
    return fetch(`${apiUrl}/update-user-about-you/${updateData.userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(updateData)
    }).then(response => {
        return response.json()
    }).catch(err => {
        console.log("PROFILE UPDATE ERR: ", err)
    })
}

export const getWallet = (userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/wallet/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    }).then(response => {
        return response.json()
    }).catch(err => {
        return err.json()
    })
}

export const updateEmployeeStatus = async (userId, isInHouseEmployee) => {
    try {
        const response = await fetch(`${apiUrl}/update-employee-status`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ userId, isInHouseEmployee }),
        });

        const data = await response.json();
        
        if (!response.ok) {
            throw new Error(data.message || "Failed to update status");
        }

        return { success: true, message: data.message, user: data.user, profile: data.profile };
    } catch (error) {
        console.error("Error updating employee status:", error);
        return { success: false, message: error.message || "Error updating status!" };
    }
};

import { Collapse } from "@mui/material";
import ReactQuill from "react-quill"; // Rich text editor
import "react-quill/dist/quill.snow.css"; // Quill CSS
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments, faPlus, faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { addCommentToTrace, addTrace, getAssemblyBySlug, toggleUpvoteTrace } from "../../actions/communityActions"; // Replace with actual API calls
import TextField from "@mui/material/TextField"; // Material-UI text field for comments
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuthStore } from "../../store/store";

const SingleSemble = () => {
    const [showTraceForm, setShowTraceForm] = useState(false); // Toggle for Trace form
    const [newTraceContent, setNewTraceContent] = useState(""); // Content of the new trace

    const [showComments, setShowComments] = useState({});
    const [showCommentForm, setShowCommentForm] = useState({});
    const [newCommentContent, setNewCommentContent] = useState({});
    const [community, setCommunity] = useState({})
    const { communitySlug, assemblySlug } = useParams();
    const navigate = useNavigate();

    const data = useAuthStore((state) => state.user);

    const [semble, setSemble] = useState(null);
    const [traces, setTraces] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isExpanded, setIsExpanded] = useState(false)

    useEffect(() => {
        const fetchAssembly = async () => {
            try {
                setLoading(true);
                const res = await getAssemblyBySlug(communitySlug, assemblySlug);
                setSemble(res.semble);
                setCommunity(res.community)
                setTraces(res?.semble?.traces || []);
                setError(null); // Reset error state if successful
            } catch (err) {
                console.error("Error fetching semble:", err);
                setError("Failed to load semble. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchAssembly();
    }, [communitySlug, assemblySlug]);

    const handleAddTrace = async () => {
        if (!data?.profile) {
            navigate("/dashboard");
        } else {
            if (!newTraceContent.trim()) return;

            setLoading(true);
            try {
                const response = await addTrace(semble?._id, { content: newTraceContent });

                if (response && response.traces) {
                    // Extract the latest trace added
                    const latestTrace = response.traces[response.traces.length - 1];
                    setTraces((prev) => [...prev, latestTrace]);
                }

                setNewTraceContent("");
                setShowTraceForm(false);
            } catch (error) {
                console.error("Error adding trace:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleAddComment = async (traceId) => {
        console.log(semble._id)
        if (!data?.profile) {
            navigate("/dashboard");
        } else {
            if (!newCommentContent[traceId]?.trim()) return;
            try {
                const response = await addCommentToTrace({
                    assemblyId: semble._id,
                    traceId: traceId,
                    content: newCommentContent[traceId],
                });

                console.log("SOMETHING", response)

                const newComment = response.data.comment;

                const updatedTraces = traces.map((trace) =>
                    trace._id === traceId
                        ? {
                            ...trace,
                            comments: [...trace.comments, newComment],
                        }
                        : trace
                );

                setTraces(updatedTraces);
                setNewCommentContent((prev) => ({ ...prev, [traceId]: "" }));
                setShowCommentForm((prev) => ({ ...prev, [traceId]: false }));
            } catch (error) {
                console.error("Error adding comment:", error);
            }
        }
    };


    const handleToggleUpvoteTrace = (traceId) => {
        toggleUpvoteTrace({ sembleId: semble._id, traceId })
            .then((res) => {
                if (res && res.trace) {
                    setTraces((prevTraces) =>
                        prevTraces.map((trace) =>
                            trace._id === res.trace._id ? res.trace : trace
                        )
                    );
                } else {
                    alert("Failed to toggle upvote. Please try again.");
                }
            })
            .catch((err) => {
                console.error("An error occurred while toggling upvote:", err);
            });
    };

    return (
        <div className="">
            <div className="">
                <div className="mx-auto">
                    <div className="card shadow border-0 mb-4">
                        <div className="card-body">
                            <h4 className="card-title">{semble?.title || "Semble Details"}</h4>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: isExpanded
                                        ? semble?.description
                                        : `${semble?.description?.substring(0, 80)}...`,
                                }}
                            />
                            {semble?.description?.length > 100 && (
                                <small className="text-primary fw-bold"
                                    onClick={() => setIsExpanded(!isExpanded)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {isExpanded ? 'Show Less' : 'Show More'}
                                </small>
                            )}
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <h3>Traces</h3>
                        {!data ? (
                            <div>
                                <span className="fw-bold text-danger">*Login and complete your profile to start tracing</span>
                            </div>
                        ) : !data?.profile ? (
                            <div>
                                <span className="fw-bold text-danger">*Complete your profile to start tracing</span>
                            </div>
                        ) : !community?.members?.includes(data?.user?._id) && !community?.adminMembers?.includes(data?.user?._id) ? (
                            <div>
                                <small className="text-danger fw-bold">*Join the community to add traces.</small>
                            </div>
                        ) : (community.allowTracing || community?.adminMembers?.includes(String(data?.user?._id))) ? (
                            data?.profile && community?.adminMembers?.includes(String(data?.user?._id)) ? (
                                <button
                                    className={`btn btn-sm fw-bold ${showTraceForm ? "btn-danger" : "btn-primary"}`}
                                    onClick={() => setShowTraceForm((prev) => !prev)}
                                >
                                    {showTraceForm ? "Cancel" : "Add Trace"}
                                </button>
                            ) : (
                                <div>
                                    <small className="text-danger fw-bold">Only admins can create traces</small>
                                </div>
                            )
                        ) : (
                            <div>
                                <small className="text-danger fw-bold">Create Trace only by admin members</small>
                            </div>
                        )}
                    </div>
                    <Collapse in={showTraceForm}>
                        <div className="card shadow border-0 my-3">
                            <div className="card-body">
                                <ReactQuill
                                    value={newTraceContent}
                                    onChange={setNewTraceContent}
                                    modules={{
                                        toolbar: [
                                            ["bold", "italic", "underline", "strike"],
                                            ["code-block"],
                                            ["link", "blockquote"],
                                        ],
                                    }}
                                    className="mb-3"
                                    placeholder="Write your trace here (code blocks supported)..."
                                />
                                <div className="text-end">
                                    <button
                                        className="btn btn-primary btn-sm fw-bold"
                                        onClick={handleAddTrace}
                                        disabled={loading}
                                    >
                                        {loading ? "Adding..." : "Add Trace"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                    {traces.length > 0 ? (
                        traces.map((trace) => (
                            <div key={trace._id} className="mb-4">
                                <div className="card shadow border-0">
                                    <div className="card-body">
                                        <p className="mb-2">
                                            <strong className="text-muted">
                                                {trace?.createdBy?.firstName}{" "}
                                                {trace?.createdBy?.lastName}
                                            </strong>
                                        </p>
                                        <div dangerouslySetInnerHTML={{ __html: trace?.content }}></div>
                                        <hr className="my-2" />
                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <span
                                                style={{ cursor: "pointer" }}
                                                className={`text-decoration-none ${showComments[trace._id] ? "text-danger" : "text-primary"
                                                    }`}
                                                onClick={() =>
                                                    setShowComments((prev) => ({
                                                        ...prev,
                                                        [trace._id]: !prev[trace._id],
                                                    }))
                                                }
                                            >
                                                <FontAwesomeIcon icon={faComments} />{" "}
                                                {showComments[trace._id] ? "Hide Comments" : "Show Comments"}
                                            </span>
                                            {data ? <>
                                                {trace?.voted ? <div>
                                                    <span onClick={() => {
                                                        handleToggleUpvoteTrace(trace._id)
                                                    }} style={{ cursor: 'pointer' }} className="badge bg-secondary rounded-pill border-secondary">{trace?.upvotes} Votes <FontAwesomeIcon size="1x" icon={faArrowDown} /></span>
                                                </div> : <div>
                                                    <span onClick={() => {
                                                        handleToggleUpvoteTrace(trace._id)
                                                    }} style={{ cursor: 'pointer' }} className="badge bg-secondary rounded-pill">{trace?.upvotes} Votes <FontAwesomeIcon size="1x" icon={faArrowUp} /> </span>
                                                </div>}
                                            </> : null}
                                            {!data && <div><small className="fw-bold text-danger">*Login and complete your profile to comment</small></div>}
                                            {data && !data?.profile && <div><small className="fw-bold text-danger">*Complete your profile to comment</small></div>}
                                            {data && data.profile &&
                                                <span
                                                    style={{ cursor: "pointer" }}
                                                    className={`text-decoration-none ${showCommentForm[trace._id]
                                                        ? "text-danger"
                                                        : "text-success"
                                                        } ms-3`}
                                                    onClick={() =>
                                                        setShowCommentForm((prev) => ({
                                                            ...prev,
                                                            [trace._id]: !prev[trace._id],
                                                        }))
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faPlus} />{" "}
                                                    {showCommentForm[trace._id] ? "Close" : "Add Comment"}
                                                </span>}
                                        </div>

                                        {/* Add Comment */}
                                        <Collapse in={showCommentForm[trace._id]}>
                                            <div>
                                                <TextField
                                                    variant="outlined"
                                                    value={newCommentContent[trace._id] || ""}
                                                    onChange={(e) =>
                                                        setNewCommentContent((prev) => ({
                                                            ...prev,
                                                            [trace._id]: e.target.value,
                                                        }))
                                                    }
                                                    className="form-control mb-3"
                                                    placeholder="Write a comment..."
                                                />
                                                <div className="text-end">
                                                    <button
                                                        className="btn btn-sm fw-bold btn-primary"
                                                        onClick={() => handleAddComment(trace._id)}
                                                    >
                                                        Add Comment
                                                    </button>
                                                </div>
                                            </div>
                                        </Collapse>

                                        {/* Comments */}
                                        <Collapse in={showComments[trace._id]}>
                                            <div>
                                                <h6>Comments:</h6>
                                                <ul className="list-unstyled">
                                                    {trace.comments?.length > 0 ? (
                                                        trace.comments.map((comment, idx) => (
                                                            <li key={idx} className="mb-2">
                                                                <strong>
                                                                    {comment.createdBy?.firstName}:
                                                                </strong>{" "}
                                                                {comment.content}
                                                            </li>
                                                        ))
                                                    ) : (
                                                        <p className="text-muted">No comments yet.</p>
                                                    )}
                                                </ul>
                                            </div>
                                        </Collapse>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-muted">No traces yet. Be the first to add one!</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SingleSemble;
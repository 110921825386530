import { Collapse, TextField, Chip, Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { createAssembly, deleteAssembly, getAssembliesByCommunity, updateAssembly } from "../../actions/communityActions";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { useAuthStore } from "../../store/store";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "code-block"],
        ["clean"],
    ],
    syntax: {
        highlight: (text) => hljs.highlightAuto(text).value,
    },
};

const SembleForm = ({ }) => {
    const [viewForm, setViewForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editingAssemblyId, setEditingAssemblyId] = useState(null);
    const [sembles, setAssemblies] = useState([]);
    const { communityId } = useParams();
    const data = useAuthStore(state => state.user);
    const [isExpanded, setIsExpanded] = useState(-1);

    const { community } = useLocation().state

    const [formData, setFormData] = useState({
        title: '',
        description: '',
    });

    const [error, setError] = useState(null)

    const [tags, setTags] = useState([]);

    useEffect(() => {
        getAssembliesByCommunity(communityId)
            .then((res) => {
                setAssemblies(res || []);
            })
            .catch((err) => {
                console.error("Error fetching sembles:", err);
            });
    }, [communityId]);

    const canEditAssembly = (semble) => {
        return (
            data.user._id === semble.createdBy._id
        );
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setError(null)
    };

    const handleQuillChange = value => {
        setFormData(prevState => ({
            ...prevState,
            description: value
        }));
        setError(null)
    };

    const handleTagsChange = (event, value) => {
        setTags(value);
        setError(null)
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.description.length <= 250) {
            setError("Description must be more than 250 characters (excluding unnecessary spaces).");
            return;
        }

        const assemblyData = {
            title: formData.title,
            description: formData.description,
            tags,
            communityId,
        };

        if (isEditing) {
            updateAssembly(editingAssemblyId, assemblyData)
                .then(() => getAssembliesByCommunity(communityId))
                .then((updatedAssemblies) => {
                    setAssemblies(updatedAssemblies || []);
                    resetForm();
                    setError(null)
                })
                .catch((err) => console.error("Error updating semble:", err));
        } else {
            createAssembly(assemblyData)
                .then(() => getAssembliesByCommunity(communityId))
                .then((updatedAssemblies) => {
                    setAssemblies(updatedAssemblies || []);
                    resetForm();
                    setError(null)
                })
                .catch((err) => console.error("Error creating semble:", err));
        }
    };

    const handleEdit = (semble) => {
        setIsEditing(true);
        setEditingAssemblyId(semble._id);
        setFormData({ title: semble.title, description: semble.description });
        setTags(semble.tags || []);
        setViewForm(true);
    };

    const resetForm = () => {
        setFormData({ title: '', description: '' });
        setTags([]);
        setViewForm(false);
        setIsEditing(false);
        setEditingAssemblyId(null);
    };

    const handleDelete = (assemblyId) => {
        deleteAssembly(assemblyId)
            .then(() => {
                // Immediately update the UI
                setAssemblies((prevAssemblies) =>
                    prevAssemblies.filter((semble) => semble._id !== assemblyId)
                );
                resetForm(); // Reset form to clear any related states
            })
            .catch((err) => {
                console.error("Error deleting semble:", err);
            });
    };

    return (
        <div className="bg-white shadow rounded-3 col-md-10 col-12  d-flex flex-wrap justify-content-center align-items-center">
            <div className="p-2 col-md-10 col-12">
                <Collapse in={!viewForm}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <h3>Sembles</h3>
                        {data?.user?._id === community.createdBy._id ?
                            <button
                                onClick={() => setViewForm(true)}
                                className="btn btn-sm btn-primary rounded-pill fw-bold"
                            >
                                <span className="px-2 fw-bold">Start New Semble</span>
                                <FontAwesomeIcon size="1x" icon={faArrowRight} />
                            </button>
                            :
                            null
                        }
                    </div>
                    <div className="mt-3">
                        {sembles.length > 0 ? (
                            sembles.map((a, i) => (
                                <div key={a._id} className="card border-0 mb-3 p-2 shadow">
                                    <div className="">
                                        <div className="d-flex flex-wrap justify-content-between align-items-center">
                                            <div className="">
                                                <Link to={`/community/${community?.slug}/semble/${a.slug}`} className="text-decoration-none">
                                                    <h5 className="card-title m-0">{a.title}</h5>
                                                </Link>
                                            </div>
                                            <div>
                                                {canEditAssembly(a) && (
                                                    <div>
                                                        <button
                                                            onClick={() => handleEdit(a)}
                                                            className="btn btn-sm btn-warning ms-2"
                                                        >
                                                            <FontAwesomeIcon size="1x" icon={faEdit} />
                                                        </button>
                                                        <button
                                                            onClick={() => handleDelete(a._id)}
                                                            className="btn btn-sm btn-danger ms-2">
                                                            <FontAwesomeIcon size="1x" icon={faTrash} />
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="py-1">
                                            {a.tags && a.tags.length > 0 && (
                                                <div className="">
                                                    {a.tags.map((tag, index) => (
                                                        <span key={index} className="badge bg-primary rounded-pill me-2">
                                                            {tag}
                                                        </span>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: isExpanded === i
                                                    ? a?.description // Full description when expanded
                                                    : `${a?.description?.substring(0, 100)}...`, // Truncated description
                                            }}
                                        />
                                        {/* Conditional rendering of the button */}
                                        {a?.description?.length > 100 && (
                                            <small className="text-primary fw-bold"
                                                onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                style={{ cursor: 'pointer' }}
                                            >
                                                {isExpanded === i ? 'Show Less' : 'Show More'}
                                            </small>
                                        )}
                                        <div className="d-flex justify-content-between align-items-center">
                                            <span className="text-muted">
                                                By {a.createdBy.firstName} {a.createdBy.lastName}
                                            </span>
                                            <span className="text-muted">
                                                {new Date(a.createdAt).toLocaleDateString()}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-muted">No sembles available.</p>
                        )}
                    </div>
                </Collapse>
                <Collapse in={viewForm}>
                    <div className="d-flex flex-wrap justify-content-between align-items-center">
                        <button
                            onClick={resetForm}
                            className="btn btn-sm btn-warning rounded-pill fw-bold"
                        >
                            <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                            <span className="px-2 fw-bold">Back</span>
                        </button>
                        <h3>{isEditing ? "Edit Semble" : "Initiate Public Semble"}</h3>
                    </div>
                    <div className="py-3">
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <TextField
                                    className="form-control"
                                    fullWidth
                                    label="Title"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleChange}
                                    variant="outlined"
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="tags" className="form-label">Tags</label>
                                <Autocomplete
                                    multiple
                                    id="tags"
                                    options={community?.tags}
                                    freeSolo
                                    value={tags}
                                    onChange={handleTagsChange}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip key={index} label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} variant="outlined" placeholder="Add tags" />
                                    )}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="description" className="form-label">Description<div>
                                    {!error ? <small className="text-muted">*Description must be more than 250 characters (excluding unnecessary spaces).</small> :
                                        <small className="text-danger fw-bold">
                                            {error}
                                        </small>
                                    }
                                </div>
                                </label>
                                <ReactQuill
                                    id="description"
                                    theme="snow"
                                    value={formData.description}
                                    onChange={handleQuillChange}
                                    placeholder="Write your semble description here..."
                                    modules={modules}
                                />
                            </div>
                            <button
                                type="submit"
                                className="btn btn-primary col-12 fw-bold"
                            >
                                {isEditing ? "Update Semble" : "Create Semble"}
                            </button>
                        </form>
                    </div>
                </Collapse>
            </div>
        </div>
    );
};

export default SembleForm;

import React, { useEffect, useState } from "react";
import { getAssembliesByCommunity, getAssembliesByCommunitySlug, getCommunityBySlug } from "../../actions/communityActions";
import { useParams, useNavigate, Outlet, Link } from "react-router-dom";
import Footer from "../../freeAccess/Footer";
import Navbar from "../../freeAccess/Navbar";
import { Collapse, IconButton, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const SemblesPage = () => {
    const { communitySlug, assemblySlug } = useParams(); // Get slugs from URL
    const navigate = useNavigate();
    const isMdScreen = useMediaQuery('(min-width: 786px)');

    const [community, setCommunity] = useState(null);
    const [sembles, setAssemblies] = useState([]);
    const [selectedAssembly, setSelectedAssembly] = useState(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const [showMenu, setShowMenu] = useState(false)

    useEffect(() => {
        const fetchCommunityData = async () => {
            try {
                const communityData = await getCommunityBySlug(communitySlug);
                setCommunity(communityData);

                const assembliesData = await getAssembliesByCommunity(communityData?._id);
                setAssemblies(assembliesData);
            } catch (error) {
                console.error("Error fetching community or sembles:", error);
            }
        };

        fetchCommunityData();
    }, [communitySlug, navigate]);

    useEffect(() => {
        if (assemblySlug && sembles.length > 0) {
            const selected = sembles.find((semble) => semble.slug === assemblySlug);
            setSelectedAssembly(selected || null);
        }
    }, [assemblySlug, sembles]);

    return (
        <>
            <Navbar />
            <div className="container-fluid col-md-8 col-12 mt-md-5 mt-2">
                <div className="row" style={{ minHeight: "50vh" }}>
                    <div className={`d-lg-none d-block justify-content-center dash-div ${showMenu ? 'bg-white rounded shadow' : ''}`} style={{
                        position: "sticky"
                    }}>
                        <div className={`d-flex justify-content-between align-items-center ${showMenu ? "" : 'rounded-3 bg-white shadow'}`}>
                            <div className="px-2">{community?.name || "N/A"}</div>
                            <IconButton onClick={() => setShowMenu(!showMenu)} >
                                <MenuIcon sx={{ strokeWidth: 3 }} />
                            </IconButton>
                        </div>
                        <Collapse in={showMenu}>
                            <div className="d-flex px-2 flex-column flex-wrap justify-content-end">
                                <h6>Sembles</h6>
                                {sembles.map((semble) => (
                                    <Link onClick={() => {setIsExpanded(false)
                                        setShowMenu(false)
                                    }} to={`/community/${community.slug}/semble/${semble.slug}`}
                                        key={semble.slug}
                                        className="text-decoration-none py-1"
                                    >
                                        {semble.title}
                                    </Link>
                                ))}
                            </div>
                        </Collapse>
                    </div>
                    <div className="col-md-4 d-lg-block d-none">
                        <div className="card border-0 shadow mb-4" style={{ position: "sticky", top: "5vh" }}>
                            <div className="card-body">
                                {community ? (
                                    <>
                                        <div className="card-text">
                                            <div>
                                                <strong>Name:</strong> {community?.name || "N/A"}
                                            </div>
                                            <div style={{ maxHeight: '50vh', overflow: 'scroll' }}>
                                                <strong>Description:</strong>{" "}
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: isExpanded
                                                            ? community?.description // Full description when expanded
                                                            : `${community?.description?.substring(0, 100)}...`, // Truncated description
                                                    }}
                                                />
                                                {/* Conditional rendering of the button */}
                                                {community?.description?.length > 100 && (
                                                    <small className="text-primary fw-bold"
                                                        onClick={() => setIsExpanded(!isExpanded)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {isExpanded ? 'Show Less' : 'Show More'}
                                                    </small>
                                                )}
                                            </div>
                                            <hr />
                                            <h6>Sembles</h6>
                                            {sembles.map((semble) => (
                                                <Link onClick={() => setIsExpanded(false)} to={`/community/${community.slug}/semble/${semble.slug}`}
                                                    key={semble.slug}
                                                    className="text-decoration-none py-1"
                                                >
                                                    {semble.title}
                                                </Link>
                                            ))}
                                        </div>
                                    </>
                                ) : (
                                    <p>Loading community details...</p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="card border-0 pb-4">
                            {selectedAssembly ? (
                                <Outlet />
                            ) : (
                                <>
                                    {community ? (
                                        <>
                                            <h5>Community Description</h5>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: isExpanded
                                                        ? community?.description // Full description when expanded
                                                        : `${community?.description?.substring(0, 100)}...`, // Truncated description
                                                }}
                                            />
                                            {/* Conditional rendering of the button */}
                                            {community?.description?.length > 100 && (
                                                <small className="text-primary fw-bold"
                                                    onClick={() => setIsExpanded(!isExpanded)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {isExpanded ? 'Show Less' : 'Show More'}
                                                </small>
                                            )}
                                        </>
                                    ) : (
                                        <p>Loading community description...</p>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default SemblesPage;

import { TextField, Chip, Autocomplete, Collapse, Switch, FormControlLabel, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { addAdminMember, createCommunity, getCommunitiesByMembership, getCommunitiesByUser, removeAdminMember, updateCommunity } from "../../actions/communityActions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "highlight.js/styles/github.css";
import hljs from "highlight.js";
import { useAuthStore } from "../../store/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "code-block"],
        ["clean"],
    ],
    syntax: {
        highlight: (text) => hljs.highlightAuto(text).value,
    },
};

const CommunityForm = () => {

    const isMdScreen = useMediaQuery('(min-width: 786px)');

    const data = useAuthStore((state) => state.user);
    const uniqueTechUsed = [...new Set(data?.profile?.workExprience?.flatMap((item) => item.techUsed))];

    const [showForm, setShowForm] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        description: "",
        allowTracing: false,
    });
    const [error, setError] = useState(null);
    const [tags, setTags] = useState([]);
    const [userCommunities, setUserCommunities] = useState([]);
    const [memberCommunities, setMemberCommunities] = useState([]);

    const [members, setMembers] = useState([]);
    const [adminMembers, setAdminMembers] = useState([]);

    const [editingCommunity, setEditingCommunity] = useState(null);
    const [showUpdateForm, setShowUpdateForm] = useState(false);
    const [showMembers, setShowMembers] = useState(false);
    const [isExpanded, setIsExpanded] = useState(-1);

    useEffect(() => {
        const fetchUserCommunities = async () => {
            try {
                const communities = await getCommunitiesByUser(data?.user?._id); // Pass the user ID to fetch their communities
                setUserCommunities(communities);
            } catch (error) {
                console.error("Error fetching communities:", error);
            }
        };

        if (data?.user?._id) fetchUserCommunities();
    }, [data?.user?._id]);

    useEffect(() => {
        const fetchMemberCommunities = async () => {
            try {
                const communities = await getCommunitiesByMembership(data?.user?._id); // Assuming this API exists
                setMemberCommunities(communities);
            } catch (error) {
                console.error("Error fetching member communities:", error);
            }
        };

        if (data?.user?._id) fetchMemberCommunities();
    }, [data?.user?._id]);

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setError(null);
    };

    const handleToggle = (e) => {
        setFormData((prevState) => ({
            ...prevState,
            allowTracing: e.target.checked,
        }));
    };

    const handleQuillChange = (value) => {
        setFormData((prevState) => ({
            ...prevState,
            description: value,
        }));
        setError(null);
    };

    const handleTagsChange = (event, value) => {
        setTags(value);
        setError(null);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (formData.description.length <= 250) {
            setError("Description must be more than 250 characters (excluding unnecessary spaces).");
            return;
        }

        const communityData = {
            name: formData.name,
            description: formData.description,
            tags,
            allowTracing: formData.allowTracing,
        };

        if (editingCommunity) {
            updateCommunity(editingCommunity._id, communityData)
                .then((updatedCommunity) => {
                    setUserCommunities((prevCommunities) =>
                        prevCommunities.map((community) =>
                            community._id === updatedCommunity._id ? updatedCommunity : community
                        )
                    );
                    setEditingCommunity(null);
                    setFormData({ name: "", description: "", allowTracing: false });
                    setTags([])
                    setShowUpdateForm(false);
                })
                .catch((err) => console.error("Error updating community:", err));
        } else {
            // Handle create
            createCommunity(communityData)
                .then((res) => {
                    setFormData({ name: "", description: "", allowTracing: false });
                    setTags([]);
                    setError(null);
                    setUserCommunities((prevCommunities) => [...prevCommunities, res]);
                })
                .catch((err) => console.error("Error creating community:", err));
        }
    };

    const handleEdit = (community) => {
        setEditingCommunity(community);
        if (community && community.addAdminMember || community.members) {
            const adminSet = new Set(community.adminMembers.map(admin => admin._id));

            // Sort: Admin members first, then regular members
            const sortedMembers = community.members.sort((a, b) => {
                return adminSet.has(a._id) ? -1 : 1;
            });

            setAdminMembers(community.adminMembers);
            setMembers(sortedMembers);
        }
        setFormData({
            name: community.name,
            description: community.description,
            allowTracing: community.allowTracing,
        });
        setTags(community.tags || []);
        setShowUpdateForm(true);
    };

    const handleAddAdmin = async ({ communityId, userId }) => {
        const result = await addAdminMember(communityId, userId);
        if (result.success) {
            setAdminMembers([...adminMembers, members.find(m => m._id === userId)]);
        }
    };

    const handleRemoveAdmin = async ({ communityId, userId }) => {
        const result = await removeAdminMember(communityId, userId);
        if (result.success) {
            setAdminMembers(adminMembers.filter(admin => admin._id !== userId));
        }
    };

    return (
        <>
            <div className="col-md-10 col-12 bg-light p-2 shadow d-flex flex-wrap justify-content-center align-items-center">
                <div className="col-md-10 col-12 d-flex flex-wrap justify-content-center align-items-center">
                    <div className="col-12">
                        <Collapse in={showForm}>
                            <div className="d-flex flex-wrap justify-content-between align-items-center pb-3">
                                <button onClick={() => setShowForm(false)} className="btn btn-sm btn-warning rounded-pill fw-bold">
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} /> Back
                                </button>
                                <h3>Community Form</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="mb-3">
                                    <TextField
                                        fullWidth
                                        label="Community Name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        variant="outlined"
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="tags" className="form-label">Tags</label>
                                    <Autocomplete
                                        multiple
                                        id="tags"
                                        options={uniqueTechUsed || []}
                                        freeSolo
                                        value={tags}
                                        onChange={handleTagsChange}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip key={index} label={option} {...getTagProps({ index })} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" placeholder="Add tags" />
                                        )}
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="description" className="form-label">
                                        Description
                                        <div>
                                            {!error ? (
                                                <small className="text-muted">*Description must be more than 250 characters (excluding unnecessary spaces).</small>
                                            ) : (
                                                <small className="text-danger fw-bold">{error}</small>
                                            )}
                                        </div>
                                    </label>
                                    <ReactQuill
                                        id="description"
                                        theme="snow"
                                        value={formData.description}
                                        onChange={handleQuillChange}
                                        placeholder="Write your community description here..."
                                        modules={modules}
                                    />
                                </div>
                                <div className="mb-3">
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={formData.allowTracing}
                                                onChange={handleToggle}
                                                color="primary"
                                            />
                                        }
                                        label="Allow Users to Trace"
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary col-12 fw-bold">
                                    Create Community
                                </button>
                            </form>
                        </Collapse>
                        <Collapse in={showUpdateForm}>
                            <div className="pb-3 d-flex flex-wrap justify-content-between align-items-center">
                                <button
                                    onClick={() => {
                                        setEditingCommunity(null);
                                        setShowUpdateForm(false);
                                        setAdminMembers([])
                                        setMembers([])
                                        setShowMembers(false)
                                    }}
                                    className="btn btn-sm btn-warning rounded-pill fw-bold"
                                >
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} /> Back
                                </button>
                                <h3>{editingCommunity ? "Update Community" : "Community Form"}</h3>
                            </div>
                            <div className={`d-flex flex-wrap pb-3 ${isMdScreen ? `justify-content-around` : 'jusitify-content-start'} align-items-center`}>
                                <small style={{ cursor: 'pointer' }}
                                    onClick={() => setShowMembers(false)}
                                    className={`fw-bold ${!showMembers ? 'text-primary text-decoration-underline' : 'text-secondary'}`}
                                >
                                    Community details
                                </small>
                                <small style={{ cursor: 'pointer' }}
                                    onClick={() => setShowMembers(true)}
                                    className={`fw-bold ${showMembers ? 'text-primary text-decoration-underline' : 'text-secondary'}`}
                                >
                                    Manage Members
                                </small>
                            </div>
                            <Collapse in={!showMembers}>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <TextField
                                            fullWidth
                                            label="Community Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                            variant="outlined"
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="tags" className="form-label">Tags</label>
                                        <Autocomplete
                                            multiple
                                            id="tags"
                                            options={uniqueTechUsed || []}
                                            freeSolo
                                            value={tags}
                                            onChange={handleTagsChange}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (
                                                    <Chip key={index} label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" placeholder="Add tags" />
                                            )}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="description" className="form-label">
                                            Description
                                            <div>
                                                {!error ? (
                                                    <small className="text-muted">*Description must be more than 250 characters (excluding unnecessary spaces).</small>
                                                ) : (
                                                    <small className="text-danger fw-bold">{error}</small>
                                                )}
                                            </div>
                                        </label>
                                        <ReactQuill
                                            id="description"
                                            theme="snow"
                                            value={formData.description}
                                            onChange={handleQuillChange}
                                            placeholder="Write your community description here..."
                                            modules={modules}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formData.allowTracing}
                                                    onChange={handleToggle}
                                                    color="primary"
                                                />
                                            }
                                            label="Allow Users to Trace"
                                        />
                                    </div>
                                    <button type="submit" className="btn btn-primary col-12 fw-bold">
                                        Update Community
                                    </button>
                                </form>
                            </Collapse>
                            <Collapse in={showMembers} >
                                <div>
                                    <h2 className="text-center">Community Members</h2>
                                    <ul className="list-group">
                                        {adminMembers.length === 0 && members.length === 0 ? (
                                            <li className="list-group-item text-center text-muted">No members in this community.</li>
                                        ) : (
                                            [...adminMembers, ...members.filter(m => !adminMembers.some(admin => admin._id === m._id))]
                                                .map(member => {
                                                    const isAdmin = adminMembers.some(admin => admin._id === member._id);
                                                    const isCreator = member._id === editingCommunity?.createdBy?._id;

                                                    return (
                                                        <li key={member._id} className={`list-group-item d-flex justify-content-between align-items-center ${isAdmin ? 'bg-light font-weight-bold' : ''}`}>
                                                            <span>
                                                                {member.firstName} {member.lastName}
                                                                {isCreator && <small className="badge bg-secondary ms-1 rounded-pill">Creator</small>}
                                                                {isAdmin && !isCreator && <small className="badge bg-primary ms-1 rounded-pill">Admin</small>}
                                                            </span>
                                                            <div>
                                                                {isAdmin ? (
                                                                    isCreator ? null : ( // ✅ Prevent creator from being removed
                                                                        <button className="btn btn-danger btn-sm" onClick={() => handleRemoveAdmin({ communityId: editingCommunity._id, userId: member._id })}>Remove Admin</button>
                                                                    )
                                                                ) : (
                                                                    <button className="btn btn-success btn-sm" onClick={() => handleAddAdmin({ communityId: editingCommunity._id, userId: member._id })}>Make Admin</button>
                                                                )}
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                        )}
                                    </ul>
                                </div>
                            </Collapse>
                        </Collapse>
                        <Collapse in={!showForm && !showUpdateForm}>
                            <div className="col-12">
                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <h3>Your Communities</h3>
                                    <button onClick={() => setShowForm(true)} className="btn btn-sm btn-success rounded-pill fw-bold">
                                        New Community <FontAwesomeIcon size="1x" icon={faArrowRight} />
                                    </button>
                                </div>
                                {userCommunities.length > 0 ? (
                                    <div>
                                        {userCommunities.map((community, i) => (
                                            <div key={community._id} className="card border-0 rounded-3 shadow p-2 my-2">
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <Link to={`${community._id}`} state={{ community: community }} className="text-decoration-none">
                                                        <h5 className="m-0">{community.name}</h5>
                                                    </Link>
                                                    <button
                                                        onClick={() => handleEdit(community)}
                                                        className="btn btn-sm btn-warning rounded-3"
                                                    >
                                                        <FontAwesomeIcon size="1x" icon={faEdit} />
                                                    </button>
                                                </div>
                                                <div>
                                                    {community?.tags?.map((tag, index) => (
                                                        <div key={index} className="badge bg-primary fw-bold rounded-pill me-2">{tag}</div>
                                                    ))}
                                                </div>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: isExpanded === i
                                                            ? community?.description // Full description when expanded
                                                            : `${community?.description?.substring(0, 100)}...`, // Truncated description
                                                    }}
                                                />
                                                {/* Conditional rendering of the button */}
                                                {community?.description?.length > 100 && (
                                                    <small className="text-primary fw-bold"
                                                        onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {isExpanded === i ? 'Show Less' : 'Show More'}
                                                    </small>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No communities created yet.</p>
                                )}
                            </div>
                            <div className="col-12 mt-4">
                                <h3>Communities You Joined</h3>
                                {memberCommunities.length > 0 ? (
                                    <div>
                                        {memberCommunities.map((community, i) => (
                                            <div key={community._id} className="card border-0 rounded-3 shadow p-2 my-2">
                                                <Link to={`${community._id}`} state={{ community: community }} className="text-decoration-none">
                                                    <h5 className="m-0">{community.name}</h5>
                                                </Link>
                                                <div>
                                                    {community?.tags?.map((tag, index) => (
                                                        <div key={index} className="badge bg-primary fw-bold rounded-pill me-2">{tag}</div>
                                                    ))}
                                                </div>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: isExpanded === i
                                                            ? community?.description // Full description when expanded
                                                            : `${community?.description?.substring(0, 100)}...`, // Truncated description
                                                    }}
                                                />
                                                {/* Conditional rendering of the button */}
                                                {community?.description?.length > 100 && (
                                                    <small className="text-primary fw-bold"
                                                        onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {isExpanded === i ? 'Show Less' : 'Show More'}
                                                    </small>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <p>No joined communities yet.</p>
                                )}
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommunityForm;
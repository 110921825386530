import { Collapse, Select, MenuItem, FormControl, InputLabel, Checkbox, ListItemText } from "@mui/material";
import { useState } from "react";
import { addTaskToRequirements } from "../../../actions/projectActions";

const Requirements = ({ projectId, projectRequirements = [], projectTasks = [], teamMembers = [] }) => {
    const [showTasks, setShowTasks] = useState(-1);
    const [selectedTaskIds, setSelectedTaskIds] = useState([]);

    const handleTaskSelect = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedTaskIds(typeof value === 'string' ? value.split(',') : value);
        console.log("Selected Task IDs:", value);
    };

    const handleAddTaskToRequirements = (updateObj) => {
        console.log(updateObj)
        addTaskToRequirements(updateObj).then(res => {
            console.log(res)
        })
    }

    return (
        <div>
            <div>
                <h4>Project Current Requirements</h4>
            </div>
            {projectTasks?.length === 0 ?
                <div className="text-center">
                    <span className="text-danger fw-bold">!! Need Tasks to align requirements</span>
                </div>
                : null}
            {projectRequirements.length !== 0 ? (
                <div>
                    {projectRequirements.map((p, i) => (
                        <div key={i}>
                            <div className="d-flex align-items-end justify-content-between p-2">
                                <div className="d-flex align-items-start justify-content-start">
                                    <span className="col-1 text-center">{i + 1}.</span>
                                    <div className="col-9">
                                        <p className="m-0 fw-bold fs-6">{p?.title}</p>
                                        <p className=" p-0 m-0" dangerouslySetInnerHTML={{ __html: p?.description }}></p>
                                    </div>
                                </div>
                                {projectTasks?.length === 0 ? null :
                                    <div className="">
                                        {showTasks === i ? (
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-sm fw-bold btn-danger" onClick={() => setShowTasks(-1)}>Close</button>
                                            </div>
                                        ) : (
                                            <div className="d-flex justify-content-end">
                                                <button className="btn btn-sm fw-bold btn-primary" onClick={() => setShowTasks(i)}>Align to Tasks</button>
                                            </div>
                                        )}
                                    </div>
                                }
                            </div>
                            <Collapse in={showTasks === i}>
                                <div className="m-2 p-2 card border-0">
                                    <FormControl fullWidth>
                                        <InputLabel>Select Tasks</InputLabel>
                                        <Select
                                            multiple
                                            label="Select Tasks"
                                            value={selectedTaskIds}
                                            onChange={handleTaskSelect}
                                            renderValue={(selected) => selected.map(id => projectTasks.find(task => task._id === id)?.taskTitle).join(', ')}
                                        >
                                            {projectTasks.map((task) => (
                                                <MenuItem key={task._id} value={task._id}>
                                                    <Checkbox checked={selectedTaskIds.indexOf(task._id) > -1} />
                                                    <ListItemText primary={task.taskTitle} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <div className="py-2">
                                        <button className="col-12 btn btn-primary fw-bold rounded-pill"
                                            onClick={() => {
                                                handleAddTaskToRequirements({
                                                    projectId,
                                                    selectedTaskIds,
                                                    targetRequirement: p._id
                                                })
                                            }}>
                                            Add this Tasks to the requirements
                                        </button>
                                    </div>
                                </div>
                            </Collapse>
                            <hr className="mx-2" />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="text-center">
                    <span className="fw-bold fs-5 text-danger">No requirements Added</span>
                </div>
            )}
        </div>
    );
};

export default Requirements;

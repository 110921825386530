import React, { useEffect, useRef, useState } from "react";
import * as Y from "yjs";
import { WebsocketProvider } from "y-websocket";
import { CodemirrorBinding } from "y-codemirror";
import CodeMirror from "codemirror";
import { useAuthStore } from '../../store/store';
import "codemirror/lib/codemirror.css";
import "codemirror/theme/dracula.css";
import "codemirror/addon/edit/closebrackets";
import "codemirror/addon/edit/matchbrackets";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/python/python";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/mode/css/css";
import "codemirror/mode/xml/xml";
import "codemirror/mode/markdown/markdown";
import "codemirror/mode/sql/sql";
import "codemirror/mode/go/go";
import "codemirror/mode/rust/rust";

const EXTENSION_MAP = {
    "js": "javascript",
    "py": "python",
    "html": "htmlmixed",
    "css": "css",
    "xml": "xml",
    "md": "markdown",
    "sql": "sql",
    "go": "go",
    "rs": "rust"
};

const CollabEditor = ({ roomId, language = "javascript" }) => {
    const user = useAuthStore(state => state.user);
    const editorRef = useRef(null);
    const editorInstanceRef = useRef(null);
    const ydocRef = useRef(new Y.Doc());
    const [currentFile, setCurrentFile] = useState(null);

    const wsProtocol = window.location.protocol === "https:" ? "wss" : "ws";
    const wsHost = window.location.hostname === "localhost" ? "localhost:4000" : "devsemble.com";
    const wsUrl = `${wsProtocol}://${wsHost}/collab?room=${encodeURIComponent(roomId)}`;

    useEffect(() => {
        if (!editorRef.current || !roomId) return;
    
        console.log("Connecting to WebSocket:", wsUrl, "for room:", roomId);
    
        // Destroy any existing Y.Doc instance before creating a new one
        if (ydocRef.current) {
            ydocRef.current.destroy();
            ydocRef.current = null;
        }
    
        const ydoc = new Y.Doc();
        ydocRef.current = ydoc;
    
        const provider = new WebsocketProvider(wsUrl, roomId, ydoc);
        provider.ws.binaryType = "arraybuffer";
    
        provider.on("status", (event) => console.log("WebSocket Status:", event.status));
    
        const yText = ydoc.getText("codemirror");
        const yUndoManager = new Y.UndoManager(yText);
    
        editorRef.current.innerHTML = "";
    
        editorInstanceRef.current = CodeMirror(editorRef.current, {
            mode: language,
            theme: "dracula",
            lineNumbers: true,
            autoCloseBrackets: true,
            matchBrackets: true,
            styleActiveLine: true,
            viewportMargin: Infinity,
            smartIndent: true,
            lineWrapping: true,
        });
    
        editorInstanceRef.current.setSize("100%", "100%");
    
        provider.awareness.setLocalStateField("user", {
            name: `${user?.user?.firstName} ${user?.user?.lastName}` || "Guest",
            color: "#" + Math.floor(Math.random() * 16777215).toString(16),
        });
    
        new CodemirrorBinding(yText, editorInstanceRef.current, provider.awareness, yUndoManager);
    
        return () => {
            console.log("Disconnecting from WebSocket:", roomId);
            provider.disconnect();
            provider.destroy();
            if (ydocRef.current) {
                ydocRef.current.destroy(); // Ensure old Y.Doc instance is removed
            }
            ydocRef.current = null;
            editorInstanceRef.current = null;
        };
    }, [roomId, language]);    

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onload = (e) => {
            const content = new TextDecoder("utf-8").decode(new Uint8Array(e.target.result));
            if (editorInstanceRef.current) {
                editorInstanceRef.current.setValue(content);
                const fileExtension = file.name.split(".").pop();
                const detectedLanguage = EXTENSION_MAP[fileExtension] || "javascript";
                editorInstanceRef.current.setOption("mode", detectedLanguage);
                setCurrentFile(file);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    const handleFileSave = () => {
        if (!editorInstanceRef.current) return;

        const content = editorInstanceRef.current.getValue();
        const fileName = currentFile ? currentFile.name : `untitled.${Object.keys(EXTENSION_MAP).find(key => EXTENSION_MAP[key] === language) || "txt"}`;

        const blob = new Blob([content], { type: "text/plain" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px", width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <input type="file" onChange={handleFileUpload} accept=".js,.py,.html,.css,.xml,.md,.sql,.go,.rs" />
                <button onClick={handleFileSave} style={{ padding: "5px 10px", backgroundColor: "#444", color: "white", border: "none", borderRadius: "5px", cursor: "pointer" }}>Save File</button>
            </div>
            <div
                ref={editorRef}
                style={{
                    height: "70vh",
                    width: "100%",
                    border: "1px solid #ccc",
                    borderRadius: "5px",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column"
                }}
            />
            <div>{wsUrl}</div>
        </div>
    );
};

export default CollabEditor;

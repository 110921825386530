import { Link } from "react-router-dom"
import Footer from "./Footer"
import Navbar from "./Navbar"
import { useEffect, useState } from "react";
import task_management from '../authFlow/assets/Frame_22.png'
import collaboration from '../authFlow/assets/Frame_26.png'
import code_editior from '../authFlow/assets/Frame_24.png'
import default_two from '../authFlow/assets/Frame_28.png'
import riidl_svv_logo from '../authFlow/assets/logo_riidl_svv.png'
import startup_india_logo from '../authFlow/assets/startup_india_logo.png'
import git_integration_with_tasks from '../authFlow/assets/git_integration_with_tasks.png'
import rank_list from '../authFlow/assets/rank_list.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodeBranch, faFileCode, faFolderPlus, faHandshake, faPeopleGroup, faProjectDiagram, faTrophy, faUser, faUserCheck, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { fetchRankedUsers, fetchRankedUsersLander } from "../actions/projectActions";
const LanderPage = () => {

    const groups = [
        {
            title: "Collaboration Made Simple",
            paragraphs: [
                {
                    heading: "Comprehensive Task Management",
                    tag: 'Stay on track',
                    content: "Manage tasks, deadlines, and communication in one platform to keep your team focused and productive.",
                    hoverImage: task_management,
                },
                {
                    heading: "Seamless Collaboration",
                    tag: 'Live code editor',
                    content: "Collaborate in real-time with a powerful live code editor designed for instant feedback, teamwork, and productivity.",
                    hoverImage: code_editior,
                },
            ],
            defaultImage: collaboration,
        },
        {
            title: "Skills That Shine, Workflows That Flow",
            paragraphs: [
                {
                    heading: "Opportunities That Value Expertise",
                    tag: 'Get your skills shine',
                    content: "Unlock project opportunities tailored to your skillset, ensuring every contribution makes an impact with DevSemble’s advanced talent-matching algorithms.",
                    hoverImage: rank_list,
                },

                {
                    heading: "Integrated Collaboration Hub",
                    tag: 'Effortless integration',
                    content: "Connect with GitHub to streamline your tasks, commits, and project updates, bridging teamwork with cutting-edge integration.",
                    hoverImage: git_integration_with_tasks,
                }
            ],
            defaultImage: default_two,
        }
    ];

    const [hoveredParagraphs, setHoveredParagraphs] = useState(Array(groups.length).fill(null));

    const getDisplayedImage = (groupIndex) => {
        const hoveredIndex = hoveredParagraphs[groupIndex];
        if (hoveredIndex !== null) {
            return groups[groupIndex].paragraphs[hoveredIndex].hoverImage;
        }
        return groups[groupIndex].defaultImage;
    };

    const handleMouseEnter = (groupIndex, paragraphIndex) => {
        const updatedState = [...hoveredParagraphs];
        updatedState[groupIndex] = paragraphIndex;
        setHoveredParagraphs(updatedState);
    };

    const handleMouseLeave = (groupIndex) => {
        const updatedState = [...hoveredParagraphs];
        updatedState[groupIndex] = null;
        setHoveredParagraphs(updatedState);
    };

    const [list, setList] = useState([])

    useEffect(() => {
        fetchRankedUsersLander().then(res => {
            setList(res?.reverse())
        }).catch(err => {
            console.log(err)
        })
    }, [])

    return (
        <>
            <Helmet>
                <title>DevSemble - A place where developers assemble.</title>
                <meta
                    name="description"
                    content="Join DevSemble, the ultimate platform for students, freelancers, and project creators. Collaborate on live projects, enhance your skills, and boost your professional growth."
                />
            </Helmet>

            <div>
                <div>
                    <Navbar />
                </div>
                <div
                    className="hero-section d-flex align-items-center py-5"
                    style={{
                        background: "linear-gradient(to right, #0275d8, #daebf9)",
                        color: "#fff",
                        minHeight: "50vh",
                    }}
                >
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 text-start text-lg-start" data-aos="fade-up">
                                <h1 className="display-4 fw-bold">
                                    A place where developers assemble.
                                </h1>
                                {/* <div data-aos="fade-up" data-aos-delay="200">
                                    <p className="lead mt-3">
                                        Join a community where skills meet opportunities, innovation
                                        thrives, and success is a shared journey. From projects to
                                        portfolios, build your legacy with <span className="fw-bold">devSemble</span>.
                                    </p>
                                </div> */}
                                {/* <div className="mt-4" data-aos="fade-up" data-aos-delay="300">
                                    <Link to="/auth">
                                        <button
                                            className="btn btn-primary rounded-pill btn me-3"
                                            style={{
                                                backgroundColor: "#fff",
                                                color: "#0275d8",
                                                border: "none",
                                                boxShadow: "0 4px 10px rgba(0,0,0,0.2)",
                                            }}
                                        >
                                            Get Started Now
                                        </button>
                                    </Link>
                                    <Link
                                        to="/user-tour"
                                        className="btn btn-outline-light rounded-pill"
                                        style={{
                                            borderColor: "#fff",
                                            color: "#fff",
                                        }}
                                    >
                                        Take a tour
                                    </Link>
                                </div> */}
                                <div
                                    className="d-flex pt-4 justify-content-start align-items-center flex-wrap gap-3"
                                    data-aos="fade-up"
                                    data-aos-delay="400"
                                >
                                    {/* Large Screens (lg and above) */}
                                    <div className="d-none d-lg-flex justify-content-center">
                                        <div>
                                            <div className="fw-bold">Backed By:</div>
                                            <img
                                                src={riidl_svv_logo}
                                                alt="riidl svv logo"
                                                className="img-fluid rounded-3 bg-white shadow"
                                                style={{ maxWidth: "200px", height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-none d-lg-flex justify-content-center">
                                        <div>
                                            <div className="fw-bold">Recognized By:</div>
                                            <img
                                                src={startup_india_logo}
                                                alt="startup india logo"
                                                className="img-fluid rounded-3 bg-white shadow"
                                                style={{ maxWidth: "200px", height: "auto" }}
                                            />
                                        </div>
                                    </div>

                                    {/* Small Screens (md and below) */}
                                    <div className="d-flex d-lg-none justify-content-center">
                                        <div>
                                            <div className="fw-bold small">Backed By:</div>
                                            <img
                                                src={riidl_svv_logo}
                                                alt="riidl svv logo"
                                                className="img-fluid rounded-3 bg-white shadow"
                                                style={{ maxWidth: "150px", height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                    <div className="d-flex d-lg-none justify-content-center">
                                        <div>
                                            <div className="fw-bold small">Recognized By:</div>
                                            <img
                                                src={startup_india_logo}
                                                alt="startup india logo"
                                                className="img-fluid rounded-3 bg-white shadow"
                                                style={{ maxWidth: "150px", height: "auto" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-center pt-5" data-aos="fade-up" data-aos-delay="400">
                            <div className="d-flex flex-wrap justify-content-center align-items-center gap-lg-5 gap-3">
                                <div>
                                    <h1 className="text-white mb-0 fw-bold">Join the Leaderboard!</h1>
                                </div>
                                <div>
                                    <Link to="/auth">
                                        <button className="btn btn-lg btn-primary fw-bold rounded-pill">
                                            Get Started Now
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="py-5 bg-light">
                    <div className="container-fluid d-flex justify-content-center">
                        <div className="card border-0 bg-light col-md-10 col-lg-8 col-12">
                            <div className=" text-start d-lg-flex flex-wrap justify-content-center align-items-center" data-aos="fade-up">
                                <h2 className="text-lg-center text-start fw-bold display-4 px-2">
                                    <span className="fw-bold">devSemble's</span> Rankings
                                </h2>
                                <h2 className="d-none d-lg-flex text-lg-center text-start fw-bold fs-4 mb-0 px-2">
                                    Powered by
                                </h2>
                                <small className="d-flex d-lg-none text-lg-center text-start fw-bold fs-6 mb-0 px-2">
                                    Powered by
                                </small>
                                <h2 className="d-block text-lg-center text-start fw-bold display-4 px-2">
                                    GitHub
                                </h2>
                            </div>
                            <div className="px-2 text-lg-center" data-aos="fade-up">
                                <span>This is Merit based ranking that used your github's repositories you add in your experiences.</span>
                            </div>
                            <div>
                                {list.map((l, i) => (
                                    <div key={i} style={{
                                    }} className="p-2 my-3 shadow-sm bg-white" data-aos="fade-up" data-aos-delay="100">
                                        <div className="text-decoration-none text-dark">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className=" col-lg-7 col-12 d-flex flex-wrap justify-content-between align-items-center">
                                                    <div className="d-flex flex-column flex-md-row align-items-md-center">
                                                        <span className="fs-3 text-decoration-none text-dark">{l?.userId?.firstName} {l?.userId?.lastName}</span>
                                                        <span className="ms-md-2 mt-2 mt-md-0">
                                                            {(() => {
                                                                const uniqueTechs = Array.from(
                                                                    new Set(l?.userId?.devSembleProfile?.workExprience?.flatMap(exp => exp.techUsed) || [])
                                                                );
                                                                return (
                                                                    <>
                                                                        {uniqueTechs.slice(0, 2).map((tech, i) => (
                                                                            <small key={i} className="badge fs-6 p-1 bg-success me-1">
                                                                                {tech}
                                                                            </small>
                                                                        ))}
                                                                        {uniqueTechs.length > 4 && <small className="text-muted">...</small>}
                                                                    </>
                                                                );
                                                            })()}
                                                        </span>
                                                    </div>
                                                    <div className="text-lg-end fs-4">
                                                        {l?.rankRange === null ? 'Not Ranked' : <>{l?.rankRange}</>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-5 col-12 d-flex flex-wrap justify-content-end">
                                                    <Link to={`public-display-rank-list/${l._id}`}>
                                                        <button className="btn btn-sm btn-primary fw-bold rounded-pill">
                                                            View Profile
                                                        </button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5 bg-light">
                    <div className="container-fluid">
                        <h2 className="text-center fw-bold mb-4 display-4" data-aos="zoom-in">Why Choose devSemble?</h2>
                        <div className="d-flex flex-wrap justify-content-evenly gap-3 py-md-4">
                            <div className="col-md-3 col-10 mb-4" data-aos="fade-up">
                                <div className="card border-0 shadow text-center">
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <FontAwesomeIcon icon={faPeopleGroup} className="display-4 mb-3" style={{ color: '#0275d8', opacity: 1 }} />
                                        <h5 className="card-title fw-bold">Real-time Collaboration</h5>
                                        <p className="card-text">Work together effortlessly.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-10 mb-4" data-aos="fade-up" data-aos-delay="100">
                                <div className="card border-0 shadow text-center">
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <FontAwesomeIcon icon={faTrophy} className="display-4 mb-3" style={{ color: '#0275d8', opacity: 1 }} />
                                        <h5 className="card-title fw-bold">Fair Ranking System</h5>
                                        <p className="card-text">Get recognized for your skills.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-10 mb-4" data-aos="fade-up" data-aos-delay="200">
                                <div className="card border-0 shadow text-center">
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <FontAwesomeIcon icon={faCodeBranch} className="display-4 mb-3" style={{ color: '#0275d8', opacity: 1 }} />
                                        <h5 className="card-title fw-bold">GitHub Integration</h5>
                                        <p className="card-text">Sync projects with GitHub.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-5 bg-light">
                    <div className="container" style={{ overflowX: 'hidden' }}>
                        <div>
                            <h2 className="text-center fw-bold mb-4 display-4" data-aos="zoom-in">devSemble Features</h2>
                        </div>
                        {groups.map((group, groupIndex) => (
                            <div key={groupIndex} className="row align-items-evenly mb-5 py-5">
                                <h2 className="d-md-none d-block fw-bold mb-4 display-6" data-aos="fade-up" data-aos-anchor-placement="center-bottom">{group.title}</h2>
                                <div className="col-md-6 d-flex order-1 order-md-2 pb-3" data-aos="fade-left" data-aos-anchor-placement="center-bottom">
                                    <img
                                        src={getDisplayedImage(groupIndex)}
                                        alt={group.title}
                                        className="img-fluid rounded-3 m-auto img-animate"
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-column justify-content-evenly order-2 order-md-1">
                                    <h2 className="d-md-block d-none fw-bold mb-4 display-6" data-aos="fade-up" data-aos-anchor-placement="center-bottom">{group.title}</h2>
                                    {group.paragraphs.map((paragraph, paragraphIndex) => (
                                        <p
                                            key={paragraphIndex}
                                            className="mb-3 fs-5"
                                            onMouseEnter={() => handleMouseEnter(groupIndex, paragraphIndex)}
                                            onMouseLeave={() => handleMouseLeave(groupIndex)}
                                            data-aos="fade-up" data-aos-anchor-placement="center-bottom" data-aos-delay="100"
                                        >
                                            <strong>{paragraph.heading}: <i>{paragraph.tag}</i></strong>
                                            <div>
                                                {paragraph.content}
                                            </div>
                                        </p>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div className="row align-items-center py-5">
                            <div className="col-md-12">
                                <h2 className="fw-bold mb-4 display-6">A Community for Growth</h2>
                                <p className="mb-3 fs-4">
                                    <strong>Empowering Growth:</strong> Be part of a community that supports professional development and builds meaningful connections.
                                </p>
                                <Link to={'/auth'}>
                                    <button className="btn btn-primary btn rounded-pill mt-3">Get Started</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-5">
                    <div className="container">
                        <h2 className="text-center fw-bold mb-4">How It Works</h2>
                        <div className="d-flex flex-wrap justify-content-evenly gap-3 py-md-4">
                            <div className="col-md-3 col-10 mb-4" data-aos="fade-up">
                                <div className="card border-0 shadow text-center">
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <FontAwesomeIcon icon={faUser} className="display-4 mb-3" style={{ color: '#0275d8', opacity: 1 }} />
                                        <h5 className="fw-bold">Sign Up</h5>
                                        <p>Create your account and join the platform.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-10 mb-4" data-aos="fade-up">
                                <div className="card border-0 shadow text-center">
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <FontAwesomeIcon icon={faFileCode} className="display-4 mb-3" style={{ color: '#0275d8', opacity: 1 }} />
                                        <h5 className="fw-bold">Host/Join Projects</h5>
                                        <p>Find or create projects that match your interests.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-10 mb-4" data-aos="fade-up">
                                <div className="card border-0 shadow text-center">
                                    <div className="card-body d-flex flex-column justify-content-between">
                                        <FontAwesomeIcon icon={faHandshake} className="display-4 mb-3" style={{ color: '#0275d8', opacity: 1 }} />
                                        <h5 className="fw-bold">Collaborate & Grow</h5>
                                        <p>Work with others and improve your skills.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Footer */}
                <div>
                    <Footer />
                </div>
            </div>
        </>
    )
}

export default LanderPage
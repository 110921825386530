import React, { useEffect, useState } from "react";
import { getAllCommunities, joinCommunity } from "../../actions/communityActions"; // Actions
import { useAuthStore } from "../../store/store"; // Store to get the current user
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import Navbar from "../../freeAccess/Navbar";
import Footer from "../../freeAccess/Footer";

const CommunitiesPage = () => {
    const [communities, setCommunities] = useState([]);
    const [joinedCommunities, setJoinedCommunities] = useState([]);
    const user = useAuthStore((state) => state.user); // Current logged-in user
    const setStateUser = useAuthStore((state) => state.setUser);
    const [isExpanded, setIsExpanded] = useState(-1);

    useEffect(() => {
        const fetchCommunities = async () => {
            try {
                const data = await getAllCommunities();
                setCommunities(data);
                setJoinedCommunities(user?.profile?.joinedCommunities || []);
            } catch (error) {
                console.error("Error fetching communities:", error);
            }
        };

        fetchCommunities();
    }, [user]);

    const handleJoinCommunity = async (communityId) => {
        try {
            const response = await joinCommunity(communityId);
            const currentUser = JSON.parse(localStorage.getItem("user"));

            const updatedUser = {
                ...currentUser,
                profile: {
                    ...currentUser.profile,
                    joinedCommunities: response?.updatedProfile?.joinedCommunities,
                },
            };

            localStorage.setItem("user", JSON.stringify(updatedUser));
            setStateUser(updatedUser);
            setJoinedCommunities(response?.updatedProfile?.joinedCommunities);
        } catch (error) {
            console.error("Error joining community:", error);
        }
    };

    return (
        <>
            <Navbar />
            <div className="py-5">
                <div className="container">
                    <h2 className="text-center mb-4 fw-bold">Explore Communities</h2>
                    <div className="d-flex flex-wrap justify-content-center align-item-center">
                        <div className="col-md-10 col-12">
                            {communities.map((community, i) => (
                                <div className="py-2" key={community?._id}>
                                    <div className="card shadow border-0 h-100">
                                        <div className="card-body d-flex flex-column justify-content-between">
                                            <div>
                                                <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                    <Link
                                                        to={`/community/${community?.slug}`}
                                                        className="text-decoration-none"
                                                        state={{ community: community }}
                                                    >
                                                        <h5 className="card-title fw-bold m-0">{community?.name}</h5>
                                                    </Link>
                                                    <div>
                                                        <div>
                                                            {!user && (
                                                                <div>
                                                                    <span className="fw-bold text-danger">
                                                                        *Login and complete your profile to Join community
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {user && !user?.profile && (
                                                                <div>
                                                                    <span className="fw-bold text-danger">
                                                                        *Complete your profile to Join community
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {user && user?.user?._id === community.createdBy._id ? (
                                                                <div>
                                                                    <span className="fw-bold text-muted">You created</span>
                                                                </div>
                                                            ) : (
                                                                user?.profile &&
                                                                !joinedCommunities?.includes(community?._id) && (
                                                                    <button
                                                                        className="btn btn-outline-success border-0 bg-transparent text-success"
                                                                        onClick={() => handleJoinCommunity(community?._id)}
                                                                    >
                                                                        Join
                                                                    </button>
                                                                )
                                                            )}

                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="text-muted">
                                                    By {community.createdBy.firstName} {community.createdBy.lastName}
                                                    &nbsp; | &nbsp; {new Date(community.createdAt).toLocaleDateString()}
                                                </p>
                                                <div>
                                                    {community.tags && community.tags.length > 0 && (
                                                        <div className="">
                                                            {community.tags.map((tag, index) => (
                                                                <span key={index} className="badge bg-primary rounded-pill me-2">
                                                                    {tag}
                                                                </span>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                        __html: isExpanded === i
                                                            ? community?.description // Full description when expanded
                                                            : `${community?.description?.substring(0, 200)}...`, // Truncated description
                                                    }}
                                                />
                                                {/* Conditional rendering of the button */}
                                                {community?.description?.length > 200 && (
                                                    <small className="text-primary fw-bold"
                                                        onClick={() => setIsExpanded(isExpanded === i ? -1 : i)}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {isExpanded === i ? 'Show Less' : 'Show More'}
                                                    </small>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default CommunitiesPage;

import { useEffect, useState } from "react"
import { useAuthStore } from "../../store/store"
import { getInHouseEmployees, getSingleProjectForTeam, addUsersToTeam, submitCoHost, removeTeamMember } from "../../actions/projectActions"
import { CircularProgress, Collapse, IconButton, MenuItem, Select, TextField, useMediaQuery } from "@mui/material"
import AuthNavbar from "../AuthNavbar"
import { AllowAdvancedAccount } from "../../manageRoutes/ProtectedRoutes"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { Link, useParams } from "react-router-dom"
import MenuIcon from '@mui/icons-material/Menu';

const ManageInHouseTeam = () => {

    const [inHouseEmployees, setInHouseEmployees] = useState([])
    const [fetchedUser, setFetchedUser] = useState([])
    const project_id = useParams().pId

    const isMdScreen = useMediaQuery('(min-width: 786px)');
    const [showMenu, setShowMenu] = useState(false)
    const [projectData, setProjectData] = useState({})
    const [activeCollapseNumber, setActiveCollapseNumber] = useState(null);
    const [collapseNumber, setCollapseNumber] = useState(0)
    const [employeeLoading, setEmployeeLoading] = useState(true)
    const [searchUsers, setSearchUsers] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [showUpdateCoHost, setShowUpdateCoHost] = useState(false)
    const [selectProjectCoHost, setSelectProjectCoHost] = useState('')
    const [removalReason, setRemovalReason] = useState(''); // Store the reason for removal
    const [showModal, setShowModal] = useState(-1)

    useEffect(() => {
        getSingleProjectForTeam(project_id).then(res => {
            setProjectData(res)
        })
        getInHouseEmployees().then(res => {
            setInHouseEmployees(res?.employees)
            setEmployeeLoading(false)
        })
    }, [refresh])

    const data = useAuthStore(state => state.user)

    const [sudoRequest, setSudoRequest] = useState([])
    const [sudoUserRequestIds, setSudoUserRequestIds] = useState([])

    const handleRemoveTeamRequest = (userId) => {
        setSudoRequest(sudoRequest.filter(e => JSON.stringify(e._id) !== JSON.stringify(userId._id)))
        setSudoUserRequestIds(sudoUserRequestIds.filter(e => JSON.stringify(e) !== JSON.stringify(userId._id)))
    }

    const handleRemoveTeamRequest2 = (userId) => {
        console.log(userId)
        setSudoRequest(sudoRequest.filter(e => JSON.stringify(e._id) !== JSON.stringify(userId.userId)))
        setSudoUserRequestIds(sudoUserRequestIds.filter(e => JSON.stringify(e.userId) !== JSON.stringify(userId.userId)))
    }

    const handleAddTeamRequest = (user) => {
        setSudoUserRequestIds((sudoUserRequestIds) => {
            const existingIndex = sudoUserRequestIds.findIndex(req => req.userId === user._id);

            if (existingIndex !== -1) {
                const updatedRequests = [...sudoUserRequestIds];
                updatedRequests[existingIndex] = {
                    ...updatedRequests[existingIndex],
                };
                return updatedRequests;
            } else {
                return [
                    ...sudoUserRequestIds,
                    {
                        userId: user._id,
                        name: `${user.firstName} ${user.lastName}`,
                    },
                ];
            }
        });

        setSudoRequest((sudoRequest) => {
            if (!sudoRequest.find(req => req.userId === user._id)) {
                return [...sudoRequest, user];
            }
            return sudoRequest;
        });
    };

    const handleChangeCoHost = (event) => {
        setSelectProjectCoHost(event.target.value)
    }

    const handleSubmitCoHost = ({ projectId, userId }) => {
        submitCoHost({ projectId, userId }).then(res => {
            if (res) {
                setRefresh(true)
                setShowUpdateCoHost(false)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    const showRequestFormButton = ({ projectData, userId, index }) => {
        let i
        for (i = 0; i <= projectData.projectTeam.length; i++) {
            if (userId._id && userId._id === projectData.projectTeam[i]?._id) {
                return (<div>Already Added</div>)
            }
        }
        for (i = 0; i <= projectData.projectTeamRequested.length; i++) {
            if (userId._id && userId._id === projectData.projectTeamRequested[i]?._id) {
                return (<div>User Requested</div>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (userId._id && userId._id === sudoRequest[i]?._id) {
                return (<button onClick={() => handleRemoveTeamRequest(userId)} className="btn btn-sm   btn-danger rounded-pill">
                    Deselect
                </button>)
            }
        }
        for (i = 0; i <= sudoRequest.length; i++) {
            if (userId._id !== sudoRequest[i]?._id) {
                return (
                    <button
                        onClick={() => {
                            setSearchUsers(true)
                            handleAddTeamRequest(userId)
                        }} className="btn btn-sm btn-success rounded-pill">
                        Select
                    </button>
                )
            }
        }
    }

    const onSubmitRequests = (projectId, selectedRequests) => {

        addUsersToTeam({ projectId, selectedIds: selectedRequests })
            .then(res => {
                if (res) {
                    setSudoRequest([]);
                    setSudoUserRequestIds([]);
                }
            })
            .catch(err => {
                console.log(err);
            });
    };

    const handleRemoveTeamMember = (projectId, selectedId) => {
        removeTeamMember({ projectId, selectedId, removalReason }).then(res => {
            if (res) {
                // navigate('/advanced-dashboard/manage-projects', {state:{status: 'negative', success : "User Deselected successfully"}})
                setRefresh(true)
            }
        }).catch(err => {
            console.log(err)
        })
        setRefresh(false)
    }

    return (
        <AuthNavbar>
            <AllowAdvancedAccount>
                <div className={`d-lg-none d-flex flex-wrap justify-content-start align-items-start shadow-sm rounded-3 px-2 ${showMenu ? 'bg-white shadow py-1' : ''}`} style={{
                    position: "sticky", zIndex: 10, top: 10
                }}>
                    <div className="col-12 d-flex flex-wrap justify-content-between align-items-center bg-white">
                        <div>
                            <Link to={`/advanced-dashboard/manage-projects/single-project/${projectData?._id}`}>
                                <button className="rounded-pill btn btn-sm btn-warning">
                                    <FontAwesomeIcon size="1x" icon={faArrowLeft} />
                                </button>
                            </Link>
                        </div>
                        <div className="fs-5 text-center col-9 px-3 py-2">
                            {projectData?.projectTitle}
                        </div>
                        <div className={`${showMenu ? "" : 'bg-white shadow'}`} style={{
                            borderRadius: '50vh'
                        }}>
                            <IconButton onClick={() => setShowMenu(!showMenu)} >
                                <MenuIcon sx={{ strokeWidth: 3 }} />
                            </IconButton>
                        </div>
                    </div>
                    <Collapse in={showMenu} className="bg-light col-12">
                        <div className="d-flex px-2 flex-column flex-wrap justify-content-end">
                            <div className="py-md-4">
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(0)
                                                setActiveCollapseNumber(0)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Search Employees
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 0 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(1)
                                                setActiveCollapseNumber(1)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Selected Employees
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 1 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                                <div className="py-1">
                                    <Link className="text-decoration-none fw-bold dash-link">
                                        <span
                                            onClick={() => {
                                                setCollapseNumber(2)
                                                setActiveCollapseNumber(2)
                                                setShowMenu(false)
                                            }}
                                            className="fw-bold text-dark">
                                            Team Employees
                                            <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 2 ? "active-arrow" : ""
                                                }`}></i>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Collapse>
                </div>
                <div className={` ${isMdScreen ? "col-10 gap-4 py-5" : 'col-11'} container-fluid d-flex justify-content-center align-items-start m-auto px-1 pb-5`}>
                    <div className={isMdScreen ? "col-lg-3" : 'd-none'}>
                        <div className="rounded-3 col-10">
                            <div className="rounded-3 p-3 d-lg-block d-none " style={{
                                backgroundColor: '#daebf9'
                            }}>
                                <div className={isMdScreen ? "d-flex flex-wrap justify-content-start align-items-center" : 'd-none'}>
                                    <Link to={`/advanced-dashboard/manage-projects/single-project/${projectData?._id}`} className="" style={{ textDecoration: 'none' }}>
                                        <button className="btn btn-warning btn-sm rounded-pill">
                                            <FontAwesomeIcon size="1x" icon={faArrowLeft} /><span className="ps-2 fw-bold">back</span>
                                        </button>
                                    </Link>
                                </div>
                                <div className="d-lg-block d-none card bg-transparent py-2 rounded-lg border-0">
                                    <div className="d-flex flex-column rounded-3 align-items-start flex-wrap justify-content-end">
                                        <div className="fs-2 fw-bold text-left">
                                            {projectData?.projectTitle}
                                        </div>
                                        <div className="py-md-4">
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(0)
                                                            setActiveCollapseNumber(0)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Search Employees
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 0 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(1)
                                                            setActiveCollapseNumber(1)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Selected Employees
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 1 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                            <div className="py-1">
                                                <Link className="text-decoration-none fw-bold dash-link">
                                                    <span
                                                        onClick={() => {
                                                            setCollapseNumber(2)
                                                            setActiveCollapseNumber(2)
                                                        }}
                                                        className="fw-bold text-dark">
                                                        Team Employees
                                                        <i className={`fa fa-arrow-right ms-2 arrow-icon ${activeCollapseNumber === 2 ? "active-arrow" : ""
                                                            }`}></i>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-md-3 col-lg-8 col-12 rounded-3 p-2" style={{
                        backgroundColor: '#daebf9'
                    }}>
                        <Collapse in={collapseNumber === 0}>
                            <div>
                                <div className="">
                                    {fetchedUser?.length === 0 ?
                                        <div className="container-fluid card border-0 p-md-2 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                            <div className="pt-2  ">
                                                <h3>Recommended by devSemble for: </h3>
                                                <div className="pb-2 d-flex flex-wrap">{projectData?.projectTech?.map((t, i) => (
                                                    <div key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                        <span className="fw-bold">{t}</span>
                                                    </div>
                                                ))}</div>
                                            </div>
                                            <hr className="mt-0" />
                                            {employeeLoading ?
                                                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1, height: `${!isMdScreen ? '90vh' : '80vh'}` }}>
                                                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                                                        <div className="text-center">
                                                            <CircularProgress />
                                                            <div>
                                                                Loading profiles please wait
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <>
                                                    {inHouseEmployees?.map((f, i) => (
                                                        <div key={i} style={{
                                                            borderBottom: '0.15rem solid grey',
                                                            borderWidth: 'thin',
                                                            borderTopLeftRadius: '0.30rem',
                                                            borderTopRightRadius: '0.30rem',
                                                            backgroundColor: 'white',
                                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                                        }} className="p-2 my-1">
                                                            <div className="d-flex flex-wrap justify-content-between align-items-center">
                                                                <div className="col-12 col-sm-6 d-flex flex-wrap justify-content-between align-items-end">
                                                                    <div className=""><span className="fs-5">{f?.firstName} {f?.lastName}</span> (<small>{f.email}</small>)</div>
                                                                </div>
                                                                <div className={`col-12 col-md-6 d-flex ${isMdScreen ? 'justify-content-end' : 'justify-content-between'} gap-4 align-items-center`}>
                                                                    {showRequestFormButton({ projectData: projectData, userId: f, index: i })}
                                                                </div>
                                                            </div>
                                                            {/* <hr /> */}
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </div> : <div className="card py-2 border-0 bg-transparent " style={{ height: "50vh", overflowY: "scroll" }}>
                                            {/* {fetchLoading ?
                                                <div className="container d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1, height: `${!isMdScreen ? '90vh' : '80vh'}` }}>
                                                    <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ flex: 1 }}>
                                                        <div className="text-center">
                                                            <CircularProgress />
                                                            <div>
                                                                {`Loading profiles for ${text} please wait`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                <>
                                                    {fetchedUser.map((f, i) => (
                                                        <div key={i} style={{
                                                            borderBottom: '0.15rem solid grey',
                                                            borderWidth: 'thin',
                                                            borderTopLeftRadius: '0.30rem',
                                                            borderTopRightRadius: '0.30rem',
                                                            backgroundColor: 'white',
                                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                                        }} className="p-2 my-1">
                                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                                <div className="col-12 col-sm-7 d-flex flex-wrap justify-content-between align-items-center ">
                                                                    <div className="col-6 fs-5   ">{f?.userId.firstName} {f?.userId.lastName}</div>
                                                                    <div className="col-6  ">
                                                                        {!f?.rankId?.rankRange === null ? 'Not Ranked' : <>{f?.rankId?.rankRange}</>}
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 col-md-5 d-flex justify-content-between align-items-center">
                                                                    {showRequestFormButton({ projectData: projectData, profile: f, index: i })}
                                                                    {showCollapsible === i ?
                                                                        <button onClick={() => setShowCollapsible(-1)} className="btn btn-sm   btn-danger rounded-pill">
                                                                            Close
                                                                        </button> :
                                                                        <button onClick={() => setShowCollapsible(i)} className="btn btn-sm   btn-primary rounded-pill">
                                                                            View Profile
                                                                        </button>}
                                                                </div>
                                                            </div>
                                                            <div className="">
                                                                <Collapse in={showSelectForm === i}>
                                                                    <div className="py-2 d-flex flex-wrap justify-content-between align-items-start gap-3">
                                                                        <div className="col-12">
                                                                            <CssTextField
                                                                                className="col-12 my-2"
                                                                                variant="outlined"
                                                                                value={amount}
                                                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                                                type="number"
                                                                                onChange={handleAmountChange}
                                                                                label="Amount"
                                                                                multiline
                                                                                maxRows="4"
                                                                            />
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <FormControl variant="outlined" className="col-12">
                                                                                <InputLabel id="requirement-label">Select Requirements</InputLabel>
                                                                                <Select
                                                                                    labelId="requirement-label"
                                                                                    id="requirement-select"
                                                                                    multiple
                                                                                    value={selectedReqs}
                                                                                    onChange={handleReqChange}
                                                                                    input={<OutlinedInput id="select-multiple-chip" label="Select Requirements" />}
                                                                                    renderValue={(selected) => (
                                                                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                                                            {selected.map((req) => (
                                                                                                <Chip
                                                                                                    key={req._id}
                                                                                                    label={req.title}
                                                                                                    className="bg-primary text-white   fs-6"
                                                                                                />
                                                                                            ))}
                                                                                        </Box>
                                                                                    )}
                                                                                >
                                                                                    {projectData.projectCurrentReq.map((req) => (
                                                                                        <MenuItem key={req._id} value={req}>
                                                                                            {req.title}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        <div className="col-12 d-flex flex-wrap justify-content-end">
                                                                            <div className="">
                                                                                {showRequestButton({ projectData: projectData, profile: f })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                                <Collapse className="" in={showCollapsible === i}>
                                                                    <div className="py-3 d-flex flex-wrap justify-content-around align-items-start">
                                                                        <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                            <div>
                                                                                <span className="fs-5"><b>Work Exprience</b></span>
                                                                                <div>
                                                                                    {f?.workExprience.map((w, i) => (
                                                                                        <div key={i} className="">
                                                                                            <span className="fs-6">{w.title}</span>
                                                                                            <div className="d-flex flex-wrap ">
                                                                                                {w?.techUsed.map((t, i) => (
                                                                                                    <small key={i} className="bg-primary rounded-pill px-2 me-2 text-white  ">
                                                                                                        <span className="small fw-bold">{t}</span>
                                                                                                    </small>
                                                                                                ))}
                                                                                            </div>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="col-12 m-0" />
                                                                        <div className="col-md-12 col-12 m-1 py-2 bg-transparent border-secondary rounded-lg">
                                                                            <div>
                                                                                <span className="fs-5"><b>Education Exprience</b></span>
                                                                                <div>
                                                                                    {f?.educationExprience.map((e, i) => (
                                                                                        <div key={i} className="">
                                                                                            <span className="fs-6">{e.courseName}</span>
                                                                                        </div>
                                                                                    ))}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Collapse>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            } */}
                                        </div>}
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 1}>
                            <div className="card border-0 my-3 p-2">
                                <div className="d-flex flex-wrap justify-content-between bg-light align-items-start">
                                    <div className="">
                                        <span className="fs-3  ">Team Members you selected</span>
                                    </div>
                                    {sudoUserRequestIds.length === 0 ? null :
                                        <button onClick={() => onSubmitRequests(projectData._id, sudoUserRequestIds)} className="btn my-2 btn-success col-md-4 rounded-pill">
                                            <span className="ps-lg-3  ">Add to Team</span>
                                        </button>}
                                </div>
                                <div className="conatiner-fluid card p-2 bg-light border-0 " style={{ height: "50vh", overflowY: "scroll" }}>
                                    {sudoUserRequestIds.map((s, i) => (
                                        <div key={i} style={{
                                            borderBottom: '0.15rem solid grey',
                                            borderWidth: 'thin',
                                            borderTopLeftRadius: '0.30rem',
                                            borderTopRightRadius: '0.30rem',
                                            backgroundColor: 'white',
                                            // boxShadow: '0 -0.1rem 0.25rem grey'
                                        }} className="p-2 my-1 rounded">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-6">
                                                    <div><span className="fs-5  ">{s.name}</span></div>
                                                </div>
                                                <div className="col-12 col-md-6 d-flex justify-content-end align-items-center">
                                                    <button onClick={() => handleRemoveTeamRequest2(s)} className="btn btn-sm   btn-danger rounded-pill">
                                                        Remove
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={collapseNumber === 2}>
                            <div>
                                {projectData.projectCoHost === undefined ? <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    {projectData?.projectTeam?.length >= 2 ? <>
                                        <span className="fs-4  ">Select Co-Host</span>
                                        <div className="col-6">
                                            <Select
                                                className="col-12 bg-white"
                                                id="demo-simple-select"
                                                value={selectProjectCoHost}
                                                label="Select Co-Host"
                                                variant="outlined"
                                                onChange={handleChangeCoHost}
                                            >
                                                {projectData.projectTeam?.map((p, i) => (
                                                    <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <button
                                            onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                            className="btn btn-primary   rounded-pill">Add as Co-Host</button>
                                    </> : <span className="text-danger  ">*For Co-host Need atleast 2 team members</span>}
                                </div> : <div className="d-flex flex-wrap justify-content-between align-items-center">
                                    <span className="fs-4">Project Co Host: {projectData.projectCoHost?.firstName} {projectData.projectCoHost?.lastName}</span>
                                    {showUpdateCoHost ?
                                        <button onClick={() => setShowUpdateCoHost(false)} className="btn btn-sm btn-danger rounded-pill">Close</button> :
                                        <button onClick={() => setShowUpdateCoHost(true)} className="btn btn-sm fw-bold btn-info rounded-pill">Update Co-host</button>
                                    }
                                </div>}
                                <Collapse in={showUpdateCoHost}>
                                    <div className="d-flex flex-wrap justify-content-between align-items-center py-2">
                                        <span className="fs-5 ">Select Co-Host</span>
                                        <div className="col-6">
                                            <Select
                                                className="col-12"
                                                id="demo-simple-select"
                                                value={selectProjectCoHost}
                                                label="Select Co-Host"
                                                variant="outlined"
                                                onChange={handleChangeCoHost}
                                            >
                                                {projectData.projectTeam?.map((p, i) => (
                                                    <MenuItem key={i} value={p._id}><span className="fs-5">{p.firstName} {p.lastName}</span></MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <button
                                            onClick={() => handleSubmitCoHost({ projectId: projectData._id, userId: selectProjectCoHost })}
                                            className="btn btn-sm fw-bold btn-primary rounded-pill">Add as Co-Host</button>
                                    </div>
                                </Collapse>
                                <hr className="m-1" />
                            </div>
                            <div className="p-2 m-2 col-12 border-0 bg-transparent rounded-lg">
                                <span className="fs-3"><b>Team Members</b></span>
                                {projectData.projectTeam?.length === 0 ? <div>
                                    <span>No Team members</span>
                                </div> : <div>
                                    {projectData.projectTeam?.map((p, i) => (
                                        <div key={i} style={{
                                            borderBottom: '0.15rem solid grey',
                                            borderWidth: 'thin',
                                            borderTopLeftRadius: '0.30rem',
                                            borderTopRightRadius: '0.30rem',
                                            backgroundColor: 'white',
                                        }} className="p-2 my-1">
                                            <div className="d-flex flex-wrap justify-content-between align-items-center ">
                                                <div className="col-12 col-md-7">
                                                    <span className="col-6 fs-5   ">{p?.firstName} {p?.lastName}</span>
                                                </div>
                                                <div>
                                                    {showModal === i ?
                                                        <button
                                                            onClick={() => setShowModal(-1)}
                                                            className="btn btn-sm fw-bold btn-danger rounded-pill"
                                                        >
                                                            Close
                                                        </button> :
                                                        <button
                                                            onClick={() => setShowModal(i)}
                                                            disabled={p._id === projectData?.authorizedGitAccess || p._id === projectData?.projectCoHost?._id}
                                                            className="btn btn-sm fw-bold btn-danger rounded-pill"
                                                        >
                                                            Remove
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                            <Collapse in={showModal === i}>
                                                <div className="py-2">
                                                    <div className="">
                                                        <h5>Remove Team Member</h5>
                                                        <p>Please provide a reason for removing this team member:</p>
                                                        <TextField
                                                            className="col-12"
                                                            variant="outlined"
                                                            value={removalReason}
                                                            onChange={(e) => setRemovalReason(e.target.value)}
                                                            label="Enter reason for removal"
                                                            multiline
                                                            maxRows="4"
                                                        />
                                                        <div className="py-1 text-end">
                                                            <button
                                                                onClick={() => handleRemoveTeamMember(projectData._id, p._id)}
                                                                className="btn btn-sm fw-bold btn-danger rounded-pill  "
                                                                disabled={!removalReason.trim()}
                                                            >
                                                                Confirm Removal
                                                            </button>
                                                            <button
                                                                onClick={() => setShowModal(-1)}
                                                                className="btn btn-sm fw-bold btn-secondary rounded-pill   ms-md-2"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Collapse>
                                        </div>
                                    ))}
                                </div>}
                            </div>
                        </Collapse>
                    </div>
                </div>
            </AllowAdvancedAccount>
        </AuthNavbar>
    )
}

export default ManageInHouseTeam
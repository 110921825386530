import { apiUrl } from './../constants'

const getToken = () => {
    const user = JSON.parse(localStorage.getItem('user')) || {};
    return user?.token || "";
};

// Create a new community
export const createCommunity = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/communities`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Fetch all communities
export const getAllCommunities = () => {
    return fetch(`${apiUrl}/communities`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const getAssembliesByCommunity = (communityId) => {
    const token = getToken();
    return fetch(`${apiUrl}/sembles/${communityId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const getCommunitiesByUser = (userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/communities-by-user/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const updateCommunity = (communityId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/communities/${communityId}`, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const getCommunitiesByMembership = (userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/communities-by-member/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Fetch a single community by slug
export const getCommunityBySlug = (slug) => {
    return fetch(`${apiUrl}/communities/${slug}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Join a community
export const joinCommunity = (communityId) => {
    const token = getToken();
    return fetch(`${apiUrl}/communities/${communityId}/join`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Leave a community
export const leaveCommunity = (communityId) => {
    const token = getToken();
    return fetch(`${apiUrl}/communities/${communityId}/leave`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const addAdminMember = (communityId, userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/add-admin-member/${communityId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userId }),
    })
        .then(response => response.json())
        .catch(err => err.json());
};

export const removeAdminMember = (communityId, userId) => {
    const token = getToken();
    return fetch(`${apiUrl}/remove-admin-member/${communityId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ userId }),
    })
        .then(response => response.json())
        .catch(err => err.json());
};

export const toggleUpvoteTrace = ({sembleId, traceId}) => {
    const token = getToken();
    return fetch(`${apiUrl}/semble/${sembleId}/trace/${traceId}/toggle-upvote`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Create a new semble
export const createAssembly = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/sembles`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Fetch all sembles for a community
export const getAssembliesByUser = (userId) => {
    return fetch(`${apiUrl}/sembles/${userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

// Fetch a single semble by slug
export const getSembleBySlug = (slug) => {
    return fetch(`${apiUrl}/semble/${slug}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const getAssembliesByCommunitySlug = (slug) => {
    return fetch(`${apiUrl}/sembles-by-community/${slug}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
}

export const getAssemblyBySlug = (communitySlug, assemblySlug) => {
    return fetch(`${apiUrl}/semble-by-slug/${communitySlug}/${assemblySlug}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then((response) => response.json())
        .catch((err) => err);
}

export const updateAssembly = (assemblyId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/sembles/${assemblyId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const deleteAssembly = (assemblyId) => {
    const token = getToken()
    return fetch(`${apiUrl}/sembles/${assemblyId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
        }
    })
        .then((response) => response.json())
        .catch((err) => err)
}

// Add a trace to an semble
export const addTrace = (assemblyId, data) => {
    const token = getToken();
    return fetch(`${apiUrl}/sembles/${assemblyId}/traces`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};

export const addCommentToTrace = (data) => {
    const token = getToken();
    return fetch(`${apiUrl}/sembles/${data?.assemblyId}/traces/${data?.traceId}/comments`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => err);
};
